import bind from "bind-decorator";
import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { NavigateFunction } from "react-router-dom";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import {
  String_Format,
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import General from "../../resources/AdminUI/General";
import { AddOrEditRoomBookingStore } from "../../store/AddOrEditRoomBookingStore";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import RoomBookingStatusTypes from "../../resources/AdminUI/RoomBookings/RoomBookingStatusTypes";
import AddOrEditRoomBooking from "../../resources/AdminUI/RoomBookings/AddOrEditRoomBooking";
import AddOrEditRoomBookingForm from "../../components/RoomBookings/AddOrEditRoomBookingForm";
import { RoomBookingTypesForAddOrEdit } from "../../enums/RoomBookings/RoomBookingTypesForAddOrEdit";
import RoomBookingTypeListForAddOrEdit from "../../resources/AdminUI/RoomBookings/RoomBookingTypeListForAddOrEdit";
import { Modal } from "antd";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import { withRouter } from "../../components/Shared/withRouter";
import ModalDialog from "../../components/Shared/ModalDialog";
import Loading from "../../components/Shared/Loading";
import ActionConfirmationModal from "../../components/Shared/ActionConfirmationModal";

interface IProps {
  navigate?: NavigateFunction;
  params: URLSearchParams;

  loading: boolean;
  hasServerSideErrors: boolean;

  initializeCreatePage?: () => void;
  initializeEditPage?: (id: number) => void;
  deleteBooking?: (id: number) => any;
}

interface IState {
  isEditMode: boolean;
  id?: number;
  isDeleteRoomBookingModalOpen: boolean;
}

class AddOrEditRoomBookingPage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    if (window.location.pathname == ApplicationPaths.AddRoomBooking) {
      this.state = {
        isEditMode: false,
        id: null,
        isDeleteRoomBookingModalOpen: false,
      };
      this.props.initializeCreatePage();
    } else if (window.location.pathname == ApplicationPaths.EditRoomBooking) {
      if (props.params.has("id")) {
        const id = parseInt(props.params.get("id"));
        this.state = {
          isEditMode: true,
          id: id,
          isDeleteRoomBookingModalOpen: false,
        };
        this.props.initializeEditPage(id);
      } else {
        this.props.navigate(ApplicationPaths.NotFound);
      }
    }
  }

  @bind
  showDeleteRoomBookingModal() {
    this.setState({ isDeleteRoomBookingModalOpen: true });
  }

  @bind
  hideDeleteRoomBookingModal() {
    this.setState({ isDeleteRoomBookingModalOpen: false });
  }

  @bind
  getAllRoomBookingStatuses() {
    var options = [];
    let keys = Object.keys(RoomBookingStatuses).filter(
      (k) => typeof RoomBookingStatuses[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = RoomBookingStatuses[key as any].toString();
      if (value != RoomBookingStatuses.AllStatuses.toString()) {
        options.push({
          value: value,
          label: RoomBookingStatusTypes.Resources[firstLetterToLowerCase(key)],
        });
      }
    });
    return options;
  }

  @bind
  getAllRoomBookingTypes() {
    var options = [];
    let keys = Object.keys(RoomBookingTypesForAddOrEdit).filter(
      (k) => typeof RoomBookingTypesForAddOrEdit[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = RoomBookingTypesForAddOrEdit[key as any].toString();
      options.push({
        value: value,
        label:
          RoomBookingTypeListForAddOrEdit.Resources[
            firstLetterToLowerCase(key)
          ],
      });
    });
    return options;
  }

  @bind
  deleteRoomBooking() {
    getPromiseFromAction(this.props.deleteBooking(this.state.id)).then(() => {
      if (!this.props.hasServerSideErrors) {
        this.props.navigate(ApplicationPaths.RoomsLogs);
      } else {
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <Helmet
          title={
            this.state.isEditMode
              ? String_Format(
                  AddOrEditRoomBooking.Resources.editRoomLog,
                  this.state.id
                )
              : AddOrEditRoomBooking.Resources.createNewRoomLog
          }
        />

        <div className="page-title">
          <div className="details-page-top">
            <div className="page-navigation">
              <span
                className="material-symbols-outlined"
                onClick={() => this.props.navigate(-1)}
              >
                arrow_back
              </span>
              <div className="page-title">
                {this.state.isEditMode
                  ? String_Format(
                      AddOrEditRoomBooking.Resources.editRoomLog,
                      this.state.id
                    )
                  : AddOrEditRoomBooking.Resources.createNewRoomLog}
              </div>
            </div>

            {this.state.isEditMode ? (
              <div className="page-actions">
                <button
                  className="btn-danger-action"
                  onClick={this.showDeleteRoomBookingModal}
                >
                  <span className="material-symbols-outlined">delete</span>
                </button>
              </div>
            ) : null}
          </div>

          <ModalDialog
            title={""}
            open={this.state.isDeleteRoomBookingModalOpen}
            onCancel={this.hideDeleteRoomBookingModal}
          >
            <ActionConfirmationModal
              contentTitle={AddOrEditRoomBooking.Resources.deleteRoomLog}
              contentText={
                AddOrEditRoomBooking.Resources
                  .areYouSureYouWantToDeleteThisRoomLog
              }
              confirmButtonText={General.Resources.deleteLabel}
              onCancelCallback={this.hideDeleteRoomBookingModal}
              onSaveCallback={this.deleteRoomBooking}
            />
          </ModalDialog>
        </div>

        {this.props.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            <AddOrEditRoomBookingForm
              isEditMode={this.state.isEditMode}
              statuses={this.getAllRoomBookingStatuses()}
              bookingTypes={this.getAllRoomBookingTypes()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        loading: state.addOrEditRoomBooking.loading,
        hasServerSideErrors: state.addOrEditRoomBooking.hasServerSideErrors,
      };
    },
    {
      initializeCreatePage:
        AddOrEditRoomBookingStore.actionCreators.initializeCreatePage,
      initializeEditPage:
        AddOrEditRoomBookingStore.actionCreators.initializeEditPage,
      deleteBooking: AddOrEditRoomBookingStore.actionCreators.deleteBooking,
    }
  )(AddOrEditRoomBookingPage as any)
);
