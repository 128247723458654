import * as React from "react";

export interface IProps {
  price: number;
  currency: string;
}

// TODO: use everywhere a price is displayed, also create a similar component for price input
const PriceLabel: React.FC<IProps> = ({ price, currency }: IProps) => {
  return (
    <span className="price-label">
      <span className="price">{price / 100}</span>
      &nbsp;
      <span className="currency">{currency}</span>
    </span>
  );
};

export default PriceLabel;
