import React, { FC } from "react";
import { Dropdown } from "antd";

import IDropdownButtonItem from "../../models/Common/IDropdownButtonItem";

interface IProps {
  text?: string;
  icon?: any;
  items: IDropdownButtonItem[];
  className?: string;
}

const DropdownButton: FC<IProps> = ({ text, items, icon, className }) => {
  const menu = {
    items: items.map((e, i) => {
      return {
        key: String(i),
        label: (
          <a onClick={e.action} className="dropdown-option">
            {e.icon && (
              <span className="material-symbols-outlined">{e.icon}</span>
            )}{" "}
            {e.text}
          </a>
        ),
      };
    }),
  };

  return (
    <div className="dropdown-menu">
      <Dropdown menu={menu} overlayClassName="dropdown-menu-overlay">
        <button className={className}>
          {text}
          {icon && <span className="material-symbols-outlined">{icon}</span>}
        </button>
      </Dropdown>
    </div>
  );
};

export default DropdownButton;
