import bind from "bind-decorator";
import React from "react";
import { ValidatedComponent } from "../../FormValidations/Base/ValidatedComponent";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import { getPromiseFromAction, nameof } from "../../../utils/utils";
import UserDetails from "../../../resources/AdminUI/Users/UserDetails";
import General from "../../../resources/AdminUI/General";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { UserDetailsStore } from "../../../store/UserDetailsStore";
import { IEditUser } from "../../../models/Users/Interfaces/IEditUser";
import { EditUserValidator } from "../../FormValidations/Validators/EditUserValidator";
import { UsersGridStore } from "../../../store/UsersGridStore";
import Validations from "../../../resources/Common/Validations";
import FormInput from "../../Shared/Fields/FormInput";

interface IProps {
  id?: number;
  firstName?: string;
  lastName?: string;
  phoneNo?: string;
  email?: string;
  teamId?: number;
  companyId?: number;
  hasServerSideErrors?: boolean;
  errors?: string;
  saveCompleted?: boolean;
  internalMetadata?: {
    department?: string;
    city?: string;
    teamLeader?: boolean;
  };
  onCancelCallback?: () => void;
  saveUserInfo?: (user: IEditUser) => any;
  resetErrors?: () => void;
  reload?: () => any;
}

interface IState {
  firstName?: string;
  lastName?: string;
  phoneNo?: string;
  email?: string;
  internalMetadata?: {
    department?: string;
    city?: string;
    teamLeader?: boolean;
  };
}

class AddOrEditUserForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      phoneNo: this.props.phoneNo,
      email: this.props.email,
      internalMetadata: {
        department: this.props.internalMetadata?.department,
        city: this.props.internalMetadata?.city,
        teamLeader: this.props.internalMetadata?.teamLeader,
      },
    };

    this.props.resetErrors();
  }

  @bind
  handleInputChange(data: string, value: any) {
    this.setState({
      [data]: value,
    });
  }

  @bind
  handleMetadataChange(data: string, value: any) {
    this.setState({
      internalMetadata: {
        ...this.state.internalMetadata,
        [data]: value,
      },
    });
  }

  @bind
  onSave() {
    this.validate(() => {
      let userToEdit: IEditUser = {
        id: this.props.id ? this.props.id : null,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phoneNo,
        email: this.state.email,
        teamId: this.props.teamId,
        companyId: this.props.companyId,
        internalMetadata: {
          ...this.state.internalMetadata,
        },
      };

      getPromiseFromAction(this.props.saveUserInfo(userToEdit)).then(() => {
        if (!this.props.hasServerSideErrors) {
          this.props.reload();
          this.props.onCancelCallback();
        }
      });
    });
  }

  componentDidUpdate(
    prevProps: Readonly<IProps & IProps>,
    snapshot?: any
  ): void {
    if (this.props.saveCompleted) {
      this.props.onCancelCallback();
    }
  }

  render() {
    return (
      <div className="modal-container">
        <div className="modal-title">
          {this.props.id
            ? UserDetails.Resources.editUserInfoLabel
            : UserDetails.Resources.addUserInfoHeader}
        </div>
        <div className="modal-description">
          <p>
            {this.props.id
              ? UserDetails.Resources.editUserInfoDescLabel
              : UserDetails.Resources.addUserInfoDesc}
          </p>
        </div>
        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {UserDetails.Resources.firstNameLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={UserDetails.Resources.firstNameLabel}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.firstName)}
              value={this.state.firstName}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.firstName))}
            />
          </div>
        </div>
        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {UserDetails.Resources.lastNameLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={UserDetails.Resources.lastNameLabel}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.lastName)}
              value={this.state.lastName}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.lastName))}
            />
          </div>
        </div>
        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {UserDetails.Resources.phoneLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={UserDetails.Resources.phoneLabel}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.phoneNo)}
              value={this.state.phoneNo}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.phoneNo))}
            />
          </div>
        </div>
        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {UserDetails.Resources.emailLabel}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={UserDetails.Resources.emailLabel}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.email)}
              value={this.state.email}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.email))}
            />
          </div>
        </div>
        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {UserDetails.Resources.cityLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={UserDetails.Resources.cityLabel}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.city)}
              value={this.state.internalMetadata.city || ""}
              onChange={this.handleMetadataChange}
              errors={this.getFieldErrors(nameof((s) => s.city))}
            />
          </div>
        </div>
        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {UserDetails.Resources.departmentLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={UserDetails.Resources.departmentLabel}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.department)}
              value={this.state.internalMetadata.department || ""}
              onChange={this.handleMetadataChange}
              errors={this.getFieldErrors(nameof((s) => s.department))}
            />
          </div>
        </div>
        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {UserDetails.Resources.teamLeaderLabel}
            </div>
          </div>
          <div className="line-action checkbox">
            <div>
              <input
                type="checkbox"
                name={nameof((s) => s.teamLeader)}
                checked={this.state.internalMetadata.teamLeader || false}
                onChange={(event) => {
                  this.setState({
                    internalMetadata: {
                      ...this.state.internalMetadata,
                      teamLeader: event.target.checked,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-secondary"
            onClick={this.props.onCancelCallback}
          >
            {General.Resources.cancelButtonLabel}
          </button>
          <button className="btn-big btn-primary" onClick={this.onSave}>
            {UserDetails.Resources.saveUserInfoButtonLabel}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: IProps) => {
    return {
      validator: EditUserValidator,
      errors: state.userDetails.errors,
      hasServerSideErrors: state.userDetails.hasServerSideErrors,
      saveCompleted: state.userDetails.saveCompleted,
      ...ownProps,
    };
  },
  {
    saveUserInfo: UserDetailsStore.actionCreators.saveUserInfo,
    resetErrors: UserDetailsStore.actionCreators.reset,
    reload: UsersGridStore.reloadAll,
  }
)(AddOrEditUserForm as any);
