import bind from "bind-decorator";
import React from "react";
import { EntityFieldInputType } from "../../utils/reactUtils";
import { getPromiseFromAction, nameof } from "../../utils/utils";
import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { AddOrEditSpaceStore } from "../../store/AddOrEditSpaceStore";
import AddOrEditWorkspaceForm from "../../resources/AdminUI/Spaces/AddOrEditWorkspaceForm";
import General from "../../resources/AdminUI/General";
import { AddOrEditSpaceFormValidator } from "../FormValidations/Validators/AddOrEditSpaceFormValidator";
import { IWorkspaceSchedule } from "../../models/Spaces/Interfaces/IWorkspaceSchedule";
import ScheduleForm from "./ScheduleForm";
import AmenitiesForm from "./AmenitiesForm";
import { ISelectListItemForAmenity } from "../../models/Common/ISelectListItemForAmenity";
import { ScheduleTab } from "../Tabs/ScheduleTab";
import AccessIntructionsFormTabsComponent from "../Tabs/AccessIntructionsFormTabsComponent";
import DescriptionTextareaFormTabsComponent from "../Tabs/DescriptionTextareaFormTabsComponent";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import { Select } from "antd";
import { withRouter } from "../Shared/withRouter";
import { NavigateFunction } from "react-router";
import TagButton from "../Shared/TagButton/TagButton";
import ModalDialog from "../Shared/ModalDialog";
import Validations from "../../resources/Common/Validations";
import ImageGalleryUpload from "../Shared/ImageGalleryUpload/ImageGalleryUpload";
import FormInput from "../Shared/Fields/FormInput";

export interface IProps {
  id?: number;
  name?: string;
  categoryId?: string;
  address?: string;
  lat?: number;
  lng?: number;
  countryId?: string;
  cityId?: string;
  currency?: string;
  spaceOffersHotDesks?: boolean;
  maxSeats?: number;
  descriptionEn?: string;
  descriptionEs?: string;
  descriptionPt?: string;
  email?: string;
  phone?: string;
  wifiName?: string;
  wifiPassword?: string;
  requireBooking?: boolean;
  accessInstructionsEn?: string;
  accessInstructionsEs?: string;
  accessInstructionsPt?: string;
  localeId?: string;
  groupId?: string;
  groupName?: string;
  groupEmailAddress?: string;
  groupPassword?: string;
  vat?: number;
  fullDayPrice?: number;
  halfDayPrice?: number;
  unredeemedPrice?: number;
  meetingRoomCommission?: number;
  privateOfficeCommission?: number;
  schedule?: IWorkspaceSchedule[];
  images?: string[];
  businessEmail?: string;
  businessPhone?: string;
  notes?: string;
  automaticEmailReceivers?: string[];
  automaticMessageByWhatsappReceivers?: string[];
  metaTitle: string;
  metaDescription: string;
  mealIncluded?: boolean;

  countries?: IAntdSelectListItem[];
  cities?: IAntdSelectListItem[];
  spaces?: IAntdSelectListItem[];
  currencies?: IAntdSelectListItem[];
  workspaceGroups?: IAntdSelectListItem[];
  languages?: IAntdSelectListItem[];
  amenities?: ISelectListItemForAmenity[];

  hasServerSideErrors?: boolean;
  errors?: string;
  setSpaceImages?: (images: string[]) => void;
  setSpaceSchedule?: (images: IWorkspaceSchedule[]) => void;
  setSpaceAmenities?: (amenities: ISelectListItemForAmenity[]) => void;

  navigate?: NavigateFunction;

  saveDetails?: (
    appendToAnExistingGroup: boolean,
    id?: number,
    name?: string,
    categoryId?: string,
    countryId?: string,
    cityId?: string,
    address?: string,
    lat?: number,
    lng?: number,
    currency?: string,
    requireBooking?: boolean,
    maxSeats?: number,
    descriptionEn?: string,
    descriptionEs?: string,
    descriptionPt?: string,
    email?: string,
    phone?: string,
    localeId?: string,
    wifiName?: string,
    wifiPassword?: string,
    accessInstructionsEn?: string,
    accessInstructionsEs?: string,
    accessInstructionsPt?: string,
    groupId?: string,
    groupName?: string,
    groupEmailAddress?: string,
    groupPassword?: string,
    halfDayPrice?: number,
    vat?: number,
    fullDayPrice?: number,
    unredeemedPrice?: number,
    meetingRoomCommission?: number,
    privateOfficeCommission?: number,
    businessEmail?: string,
    businessPhone?: string,
    notes?: string,
    automaticEmailReceivers?: string[],
    automaticMessageByWhatsappReceivers?: string[],
    metaTitle?: string,
    metaDescription?: string,
    mealIncluded?: boolean
  ) => any;
}

export interface IState {
  id?: number;
  name?: string;
  categoryId?: string;
  address?: string;
  countryId?: string;
  cityId?: string;
  lat?: number;
  lng?: number;
  currency?: string;
  spaceOffersHotDesks?: boolean;
  requireBooking?: boolean;
  maxSeats?: number;
  descriptionEn?: string;
  descriptionEs?: string;
  descriptionPt?: string;
  email?: string;
  phone?: string;
  localeId?: string;
  wifiName?: string;
  wifiPassword?: string;
  accessInstructionsEn?: string;
  accessInstructionsEs?: string;
  accessInstructionsPt?: string;
  groupId?: string;
  appendToAnExistingGroup?: boolean;
  createANewGroup?: boolean;
  groupName?: string;
  groupEmailAddress?: string;
  groupPassword?: string;
  vat?: number;
  fullDayPrice?: number;
  halfDayPrice?: number;
  unredeemedPrice?: number;
  meetingRoomCommission?: number;
  privateOfficeCommission?: number;
  businessEmail?: string;
  businessPhone?: string;
  notes?: string;
  automaticEmailReceivers?: string[];
  automaticMessageByWhatsappReceivers?: string[];
  metaTitle?: string;
  metaDescription?: string;
  mealIncluded?: boolean;
  isAmenitiesModalOpen?: boolean;
  isScheduleModalOpen?: boolean;
}

class AddOrEditSpaceForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      name: this.props.name,
      categoryId: this.props.categoryId,
      address: this.props.address,
      countryId: this.props.countryId,
      cityId: this.props.cityId,
      lat: this.props.lat,
      lng: this.props.lng,
      currency: this.props.currency,
      spaceOffersHotDesks: this.props.spaceOffersHotDesks,
      requireBooking: this.props.requireBooking,
      maxSeats: this.props.maxSeats,
      descriptionEn: this.props.descriptionEn,
      descriptionEs: this.props.descriptionEs,
      descriptionPt: this.props.descriptionPt,
      email: this.props.email,
      phone: this.props.phone,
      localeId: this.props.localeId,
      wifiName: this.props.wifiName,
      wifiPassword: this.props.wifiPassword,
      accessInstructionsEn: this.props.accessInstructionsEn,
      accessInstructionsEs: this.props.accessInstructionsEs,
      accessInstructionsPt: this.props.accessInstructionsPt,
      groupId: this.props.groupId,
      appendToAnExistingGroup: true,
      createANewGroup: false,
      groupName: this.props.groupName,
      groupEmailAddress: this.props.groupEmailAddress,
      groupPassword: this.props.groupPassword,
      vat: this.props.vat,
      fullDayPrice: this.props.fullDayPrice,
      halfDayPrice: this.props.halfDayPrice,
      unredeemedPrice: this.props.unredeemedPrice,
      meetingRoomCommission: this.props.meetingRoomCommission,
      privateOfficeCommission: this.props.privateOfficeCommission,
      businessEmail: this.props.businessEmail,
      businessPhone: this.props.businessPhone,
      notes: this.props.notes,
      automaticEmailReceivers: this.props.automaticEmailReceivers,
      automaticMessageByWhatsappReceivers:
        this.props.automaticMessageByWhatsappReceivers,
      metaTitle: this.props.metaTitle,
      metaDescription: this.props.metaDescription,
      mealIncluded: this.props.mealIncluded,
      isAmenitiesModalOpen: false,
      isScheduleModalOpen: false,
    };
  }

  @bind
  hideAmenitiesModal() {
    this.setState({ isAmenitiesModalOpen: false });
  }

  @bind
  showAmenitiesModal() {
    this.setState({ isAmenitiesModalOpen: true });
  }

  @bind
  hideScheduleModal() {
    this.setState({ isScheduleModalOpen: false });
  }

  @bind
  showScheduleModal() {
    this.setState({ isScheduleModalOpen: true });
  }

  @bind
  handleOnAutomaticEmailReceiversListChange(list: string[]) {
    this.setState({ automaticEmailReceivers: list });
  }

  @bind
  handleOnautomaticMessageByWhatsappReceiversListChange(list: string[]) {
    this.setState({ automaticMessageByWhatsappReceivers: list });
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      [name]: data,
    });
  }

  @bind
  handlePriceInputChange(name: string, data: any) {
    this.setState({
      [name]: Math.round(data * 100),
    });
  }

  @bind
  handleSearchDropDownInputChange(name: string, data: any) {
    if (data.length > 0) {
      this.setState({
        [name]: data[0].value,
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  }

  @bind
  onKeyDownForNumberInput(e: any) {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  onKeyDownForLatAndLngNumberInput(e: any) {
    if (["e", "E", "+"].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  saveDetails() {
    this.validate(() => {
      let maxCapacityPerDay = this.state.spaceOffersHotDesks
        ? this.state.maxSeats
        : 0;
      getPromiseFromAction(
        this.props.saveDetails(
          this.state.appendToAnExistingGroup,
          this.state.id,
          this.state.name,
          this.state.categoryId,
          this.state.countryId,
          this.state.cityId,
          this.state.address,
          this.state.lat,
          this.state.lng,
          this.state.currency,
          this.state.requireBooking,
          maxCapacityPerDay,
          this.state.descriptionEn,
          this.state.descriptionEs,
          this.state.descriptionPt,
          this.state.email,
          this.state.phone,
          this.state.localeId,
          this.state.wifiName,
          this.state.wifiPassword,
          this.state.accessInstructionsEn,
          this.state.accessInstructionsEs,
          this.state.accessInstructionsPt,
          this.state.groupId,
          this.state.groupName,
          this.state.groupEmailAddress,
          this.state.groupPassword,
          this.state.vat,
          this.state.fullDayPrice,
          this.state.halfDayPrice,
          this.state.unredeemedPrice,
          this.state.meetingRoomCommission,
          this.state.privateOfficeCommission,
          this.state.businessEmail,
          this.state.businessPhone,
          this.state.notes,
          this.state.automaticEmailReceivers,
          this.state.automaticMessageByWhatsappReceivers,
          this.state.metaTitle,
          this.state.metaDescription,
          this.state.mealIncluded
        )
      ).then(() => {
        if (!this.props.hasServerSideErrors) {
          this.props.navigate(ApplicationPaths.Spaces);
        }
      });
    });
  }

  render() {
    return (
      <div className="form-container">
        <div>
          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.spaceName}
                <span className="mandatory">*</span>
              </div>
              <div className="description">
                {
                  AddOrEditWorkspaceForm.Resources
                    .nameVisibleInMobileApplication
                }
              </div>
            </div>

            <div className="line-action">
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.spaceName}
                inputType={EntityFieldInputType.Text}
                className={"form-control"}
                value={this.state.name}
                name={nameof((s) => s.name)}
                onChange={this.handleInputChange}
                errors={this.getFieldErrors(nameof((s) => s.name))}
              />
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.spaceType}
                <span className="mandatory">*</span>
              </div>
              <div className="description">
                {
                  AddOrEditWorkspaceForm.Resources
                    .selectSpaceTypeByChoosingFromDropdown
                }
              </div>
            </div>
            <div className="line-action">
              <div className="input-group form-select">
                <Select
                  options={this.props.spaces}
                  onChange={(value: string, option: any) => {
                    this.handleInputChange(
                      nameof((s) => s.categoryId),
                      value
                    );
                  }}
                  value={
                    this.state.categoryId
                      ? this.state.categoryId.toString()
                      : null
                  }
                  placeholder={General.Resources.select}
                ></Select>
              </div>
              {this.getFieldErrors(nameof((s) => s.categoryId)).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.doesTheSpaceHaveIncluedMeal}
                <span className="mandatory">*</span>
              </div>
              <div>
                {AddOrEditWorkspaceForm.Resources.doesTheSpaceHaveIncluedMeal}
              </div>
            </div>
            <div className="line-action checkbox">
              <div>
                <input
                  type="checkbox"
                  name={nameof((s) => s.mealIncluded)}
                  checked={this.state.mealIncluded}
                  onChange={(event) => {
                    this.setState({
                      mealIncluded: event.target.checked,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.spaceAddress}
                <span className="mandatory">*</span>
              </div>
              <div className="description">
                {
                  AddOrEditWorkspaceForm.Resources
                    .addressWillbeVisibleInMobileApp
                }
              </div>
            </div>

            <div className="line-action">
              <div className="input-group form-select">
                <Select
                  options={this.props.countries}
                  onChange={(value, option) => {
                    this.setState({ cityId: "", countryId: value.toString() });
                  }}
                  placeholder={AddOrEditWorkspaceForm.Resources.selectCountry}
                  value={
                    this.state.countryId
                      ? this.state.countryId.toString()
                      : null
                  }
                  showSearch
                  optionFilterProp="label"
                ></Select>
              </div>
              {this.getFieldErrors(nameof((s) => s.countryId)).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
              <div className="input-group form-select">
                <Select
                  options={
                    this.state.countryId
                      ? this.props.cities.filter((e) =>
                          this.state.countryId
                            ? e.group == this.state.countryId
                            : true
                        )
                      : this.props.cities
                  }
                  onChange={(value, option) => {
                    this.setState({ cityId: value.toString() });
                  }}
                  placeholder={AddOrEditWorkspaceForm.Resources.selectCity}
                  value={
                    this.state.cityId ? this.state.cityId.toString() : null
                  }
                  showSearch
                  optionFilterProp="label"
                ></Select>
              </div>
              {this.getFieldErrors(nameof((s) => s.cityId)).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.completeAddress}
                inputType={EntityFieldInputType.Text}
                className={"form-control"}
                value={this.state.address}
                name={nameof((s) => s.address)}
                onChange={this.handleInputChange}
                errors={this.getFieldErrors(nameof((s) => s.address))}
              />
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.gpsCoordinates}
                <span className="mandatory">*</span>
              </div>
              <div className="description">
                {
                  AddOrEditWorkspaceForm.Resources
                    .distanceAndMapLocationAreBasedOnThem
                }
              </div>
            </div>

            <div className="line-action">
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.latitude}
                inputType={EntityFieldInputType.Number}
                className={"form-control"}
                value={this.state.lat}
                name={nameof((s) => s.lat)}
                onChange={this.handleInputChange}
                onKeyDown={this.onKeyDownForLatAndLngNumberInput}
                errors={this.getFieldErrors(nameof((s) => s.lat))}
              />
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.longitude}
                inputType={EntityFieldInputType.Number}
                className={"form-control"}
                value={this.state.lng}
                name={nameof((s) => s.lng)}
                onChange={this.handleInputChange}
                onKeyDown={this.onKeyDownForLatAndLngNumberInput}
                errors={this.getFieldErrors(nameof((s) => s.lng))}
              />
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.spaceCurrency}
                <span className="mandatory">*</span>
              </div>
              <div className="description">
                {
                  AddOrEditWorkspaceForm.Resources
                    .selectingTheRightCurrencyIsImportantforCheckoutFlowAndGeneratingPrices
                }
              </div>
            </div>
            <div className="line-action">
              <div className="input-group form-select">
                <Select
                  options={this.props.currencies}
                  onChange={(value, option) => {
                    this.setState({ currency: value.toString() });
                  }}
                  placeholder={General.Resources.select}
                  value={
                    this.state.currency ? this.state.currency.toString() : null
                  }
                  showSearch
                  optionFilterProp="label"
                ></Select>
              </div>
              {this.getFieldErrors(nameof((s) => s.currency)).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
            </div>
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditWorkspaceForm.Resources.desksAvailability}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.doesTheSpaceOffersHotDesks}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .ifNotSpaceWillNotBeVisibleInMobileDesksSection
              }
            </div>
          </div>
          <div className="line-action checkbox">
            <div>
              <input
                type="checkbox"
                name={nameof((s) => s.spaceOffersHotDesks)}
                checked={this.state.spaceOffersHotDesks}
                onChange={(event) => {
                  this.setState({
                    spaceOffersHotDesks: event.target.checked,
                    requireBooking: event.target.checked,
                  });
                }}
              />
            </div>
            <div>
              {AddOrEditWorkspaceForm.Resources.doesTheSpaceOffersHotDesks}
            </div>
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.doesTheSpaceRequirePriorBooking}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .selectIfTheSpaceRequiresBookingCompleteMaxDesksAvailable
              }
            </div>
          </div>

          <div className="line-action">
            <label className="form-check">
              <input
                type="checkbox"
                name={nameof((s) => s.requireBooking)}
                checked={this.state.requireBooking}
                disabled={!this.state.spaceOffersHotDesks}
                onChange={(event) => {
                  this.setState({
                    requireBooking: event.target.checked,
                  });
                }}
              />
              <label>
                {this.state.requireBooking
                  ? AddOrEditWorkspaceForm.Resources
                      .yesThisSpaceRequiresPriorBooking
                  : AddOrEditWorkspaceForm.Resources
                      .noThisIsAWalkInSpaceAndDoesNotRequireBooking}
              </label>
            </label>

            <FormInput
              placeholder={
                AddOrEditWorkspaceForm.Resources.maximumCapacityPerDay
              }
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.maxSeats}
              name={nameof((s) => s.maxSeats)}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              readOnly={
                !this.state.spaceOffersHotDesks || !this.state.requireBooking
              }
              errors={this.getFieldErrors(nameof((s) => s.maxSeats))}
            />
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditWorkspaceForm.Resources.spaceDisplayAndDetails}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.fullDescription}
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .recommendBetween200And400Characters
              }
            </div>
          </div>

          <div className="line-action">
            <DescriptionTextareaFormTabsComponent
              componentId={`space-description-${this.props.id}`}
              textEn={this.props.descriptionEn}
              nameEn={nameof((s) => s.descriptionEn)}
              placeholderEn={AddOrEditWorkspaceForm.Resources.fullDescription}
              textEs={this.props.descriptionEs}
              nameEs={nameof((s) => s.descriptionEs)}
              placeholderEs={AddOrEditWorkspaceForm.Resources.fullDescription}
              textPt={this.props.descriptionPt}
              namePt={nameof((s) => s.descriptionPt)}
              placeholderPt={AddOrEditWorkspaceForm.Resources.fullDescription}
              onChangeCallback={this.handleInputChange}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.whoWillReceiveAutomatedEmails}
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .addPeopleToReceiveAutomatedEmails
              }
            </div>
          </div>
          <div className="line-action">
            <TagButton
              tags={this.state.automaticEmailReceivers}
              buttonText={AddOrEditWorkspaceForm.Resources.receiverEmailAddress}
              onListChange={this.handleOnAutomaticEmailReceiversListChange}
              type="email"
            />
            {this.getFieldErrors(nameof((s) => s.automaticEmailReceivers)).map(
              (err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              )
            )}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {
                AddOrEditWorkspaceForm.Resources
                  .whoWillReceiveAutomatedMessagesByWhatsapp
              }
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .addPeopleToReceiveAutomatedMessagesByWhatsapp
              }
            </div>
          </div>
          <div className="line-action">
            <TagButton
              tags={this.state.automaticMessageByWhatsappReceivers}
              buttonText={AddOrEditWorkspaceForm.Resources.receiverPhoneNumber}
              onListChange={
                this.handleOnautomaticMessageByWhatsappReceiversListChange
              }
              type="phone"
            />
            {this.getFieldErrors(
              nameof((s) => s.automaticMessageByWhatsappReceivers)
            ).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.spacePhotographs}
            </div>
            <div className="description">
              {AddOrEditWorkspaceForm.Resources.acceptedFormats}
            </div>
          </div>

          <div className="line-action">
            {this.getFieldErrors(nameof((s) => s.images)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
            <ImageGalleryUpload
              images={this.props.images}
              onSaveCallback={this.props.setSpaceImages}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.spaceAmenities}
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .selectFromTheListAndHighlightTheImportantOnes
              }
            </div>
          </div>

          <div className="line-action">
            <button
              className="btn-big btn-secondary in-form"
              onClick={() => this.showAmenitiesModal()}
            >
              {AddOrEditWorkspaceForm.Resources.selectAmenities}
            </button>
            <ModalDialog
              title={AddOrEditWorkspaceForm.Resources.selectSpaceAmenities}
              open={this.state.isAmenitiesModalOpen}
              onCancel={this.hideAmenitiesModal}
            >
              <AmenitiesForm
                additionalInofrmation={
                  AddOrEditWorkspaceForm.Resources
                    .pleaseSelectAllAvailableAmenitiesHighlightImportantAmenitiesAndTheyWillAppearOnTheMainSpaceDetailsScreen
                }
                onSaveCallback={this.props.setSpaceAmenities}
                onCloseCallback={this.hideAmenitiesModal}
              ></AmenitiesForm>
            </ModalDialog>
            {this.props.amenities && this.props.amenities.length > 0 ? (
              <div>
                <div className="form-selection-displayed">
                  <span>{General.Resources.highlighted}</span>
                  {this.props.amenities
                    .filter((e) => e.selected && e.isHighlighted)
                    .map((e) => e.text)
                    .join("| ")}
                </div>
                <div className="form-selection-displayed">
                  <span>{General.Resources.others}</span>
                  {this.props.amenities
                    .filter((e) => e.selected && !e.isHighlighted)
                    .map((e) => e.text)
                    .join("| ")}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.spaceSchedule}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .completeSpaceScheduleWithOpeningAndClosingHours
              }
            </div>
          </div>
          <div className="line-action">
            <button
              className="btn-big btn-secondary in-form"
              onClick={this.showScheduleModal}
            >
              {AddOrEditWorkspaceForm.Resources.selectSchedule}
            </button>
            <ModalDialog
              title={AddOrEditWorkspaceForm.Resources.setSpacesProgram}
              open={this.state.isScheduleModalOpen}
              onCancel={this.hideScheduleModal}
            >
              <ScheduleForm
                schedule={this.props.schedule}
                additionalInofrmation={
                  AddOrEditWorkspaceForm.Resources
                    .pleaseCompleteEverydaysProgramIfTheSpaceIsClosed
                }
                onSaveCallback={this.props.setSpaceSchedule}
                onCloseCallback={this.hideScheduleModal}
              ></ScheduleForm>
            </ModalDialog>

            <div>
              {this.props.schedule.filter(
                (e) => e.openHour == null && e.closeHour == null && !e.isClosed
              ).length > 0 ? null : (
                <ScheduleTab schedule={this.props.schedule} />
              )}
            </div>
            <div>
              {this.getFieldErrors(nameof((s) => s.schedule)).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.publicContactInformation}
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .spacePhoneNumberAndEmailAddressWillBeDisplayedInAppForUsers
              }
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditWorkspaceForm.Resources.emailAddress}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.email}
              name={nameof((s) => s.email)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.email))}
            />

            <FormInput
              placeholder={AddOrEditWorkspaceForm.Resources.phoneNumber}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.phone}
              name={nameof((s) => s.phone)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.phone))}
            />
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditWorkspaceForm.Resources.automatedEmails}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.emailLanguage}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .chooseInWhichLanguageTheEmailsShouldBeSent
              }
            </div>
          </div>
          <div className="line-action">
            <div className="input-group form-select">
              <Select
                options={this.props.languages}
                onChange={(value: string, option: any) => {
                  this.handleInputChange(
                    nameof((s) => s.localeId),
                    value
                  );
                }}
                value={
                  this.state.localeId ? this.state.localeId.toString() : null
                }
                placeholder={General.Resources.select}
              ></Select>
            </div>
            {this.getFieldErrors(nameof((s) => s.localeId)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.wifiInfoAndAccess}
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .atEveryBookingUsersGetAnAutomatedEmailwithWiFiDetailsAndAccessInfo
              }
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditWorkspaceForm.Resources.wifiNetwork}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.wifiName}
              name={nameof((s) => s.wifiName)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.wifiName))}
            />

            <FormInput
              placeholder={AddOrEditWorkspaceForm.Resources.wifiPassword}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.wifiPassword}
              name={nameof((s) => s.wifiPassword)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.wifiPassword))}
            />

            <AccessIntructionsFormTabsComponent
              componentId={`space-access-instructions-${this.props.id}`}
              textEn={this.props.accessInstructionsEn}
              nameEn={nameof((s) => s.accessInstructionsEn)}
              placeholderEn={
                AddOrEditWorkspaceForm.Resources.buildingAccessInfoWithExamples
              }
              textEs={this.props.accessInstructionsEs}
              nameEs={nameof((s) => s.accessInstructionsEs)}
              placeholderEs={
                AddOrEditWorkspaceForm.Resources.buildingAccessInfoWithExamples
              }
              textPt={this.props.accessInstructionsPt}
              namePt={nameof((s) => s.accessInstructionsPt)}
              placeholderPt={
                AddOrEditWorkspaceForm.Resources.buildingAccessInfoWithExamples
              }
              onChangeCallback={this.handleInputChange}
            />
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditWorkspaceForm.Resources.spaceDashboardAccess}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditWorkspaceForm.Resources.selectGroupOrCreateANewOne}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditWorkspaceForm.Resources
                  .chooseGroupForThisSpaceOrCreateNewGroup
              }
            </div>
          </div>
          <div className="line-action">
            <div className="line-action radiobox">
              <div>
                <input
                  type="radio"
                  name={nameof((s) => s.appendToAnExistingGroup)}
                  checked={this.state.appendToAnExistingGroup}
                  onChange={(event) => {
                    this.setState({
                      appendToAnExistingGroup: event.target.checked,
                      createANewGroup: !event.target.checked,
                      groupPassword: "",
                      groupEmailAddress: "",
                      groupName: "",
                    });
                  }}
                />
              </div>
              <div>
                {
                  AddOrEditWorkspaceForm.Resources
                    .appendSpaceToExistingGroupAndExistingCredentials
                }
              </div>
            </div>

            <div className="input-group form-select">
              <Select
                options={this.props.workspaceGroups}
                onChange={(value, option) => {
                  this.handleInputChange(
                    nameof((e) => e.groupId),
                    value
                  );
                }}
                value={
                  this.state.groupId ? this.state.groupId.toString() : null
                }
                showSearch
                optionFilterProp="label"
                placeholder={General.Resources.select}
                disabled={!this.state.appendToAnExistingGroup}
              ></Select>
              {this.getFieldErrors(nameof((s) => s.groupId)).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ))}
            </div>

            <div className="line-action radiobox" style={{ marginTop: "16px" }}>
              <div>
                <input
                  type="radio"
                  name={nameof((s) => s.createANewGroup)}
                  checked={this.state.createANewGroup}
                  onChange={(event) => {
                    this.setState({
                      createANewGroup: event.target.checked,
                      appendToAnExistingGroup: !event.target.checked,
                      groupId: "",
                    });
                  }}
                />
              </div>
              <div>
                {AddOrEditWorkspaceForm.Resources.createNewGroupForThisSpace}
              </div>
            </div>

            <FormInput
              placeholder={AddOrEditWorkspaceForm.Resources.groupName}
              readOnly={!this.state.createANewGroup}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.groupName}
              name={nameof((s) => s.groupName)}
              onChange={this.handleInputChange}
            />
            <FormInput
              placeholder={AddOrEditWorkspaceForm.Resources.groupEmailAddress}
              readOnly={!this.state.createANewGroup}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.groupEmailAddress}
              name={nameof((s) => s.groupEmailAddress)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.groupEmailAddress))}
            />
            <FormInput
              placeholder={AddOrEditWorkspaceForm.Resources.password}
              readOnly={!this.state.createANewGroup}
              inputType={EntityFieldInputType.Password}
              className={"form-control"}
              value={this.state.groupPassword}
              name={nameof((s) => s.groupPassword)}
              onChange={this.handleInputChange}
            />
            <div>
              {this.getFieldErrors(nameof((state) => state.groups)).map(
                (err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                )
              )}
            </div>
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditWorkspaceForm.Resources.moreInfoMetadata}
        </h2>

        <div>
          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.internalSpaceCost}
              </div>
              <div className="description">
                {
                  AddOrEditWorkspaceForm.Resources
                    .completeInformationAboutSpaceFullHalfDayAndUnredeemedPricingOffer
                }
              </div>
            </div>

            <div className="line-action">
              <div className="field-more-info first-item">
                {AddOrEditWorkspaceForm.Resources.VAT}
              </div>
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.VAT}
                inputType={EntityFieldInputType.Number}
                className={"form-control"}
                value={this.state.vat}
                name={nameof((s) => s.vat)}
                onChange={this.handleInputChange}
                errors={this.getFieldErrors(nameof((s) => s.vat))}
              />
              <div className="field-more-info first-item">Full day price</div>
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.fullDay}
                inputType={EntityFieldInputType.Number}
                className={"form-control"}
                value={this.state.fullDayPrice / 100}
                name={nameof((s) => s.fullDayPrice)}
                onChange={this.handlePriceInputChange}
                errors={this.getFieldErrors(nameof((s) => s.fullDayPrice))}
              />

              <div className="field-more-info">Half day price</div>
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.halfDay}
                inputType={EntityFieldInputType.Number}
                className={"form-control"}
                value={this.state.halfDayPrice / 100}
                name={nameof((s) => s.halfDayPrice)}
                onChange={this.handlePriceInputChange}
                errors={this.getFieldErrors(nameof((s) => s.halfDayPrice))}
              />

              <div className="field-more-info">Unredeemed price</div>
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.unredeemed}
                inputType={EntityFieldInputType.Number}
                className={"form-control"}
                value={this.state.unredeemedPrice / 100}
                name={nameof((s) => s.unredeemedPrice)}
                onChange={this.handlePriceInputChange}
                errors={this.getFieldErrors(nameof((s) => s.unredeemedPrice))}
              />
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.commission}
              </div>
              <div className="description">
                {
                  AddOrEditWorkspaceForm.Resources
                    .ifComissionIs30CompleteWithValue30
                }
              </div>
            </div>

            <div className="line-action">
              <div className="field-more-info first-item">
                Meeting Room Commission
              </div>
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.percent}
                inputType={EntityFieldInputType.Number}
                className={"form-control"}
                value={this.state.meetingRoomCommission}
                name={nameof((s) => s.meetingRoomCommission)}
                onChange={this.handleInputChange}
                onKeyDown={this.onKeyDownForNumberInput}
                errors={this.getFieldErrors(
                  nameof((s) => s.meetingRoomCommission)
                )}
              />

              <div className="field-more-info">Private Office Commission</div>
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.percent}
                inputType={EntityFieldInputType.Number}
                className={"form-control"}
                value={this.state.privateOfficeCommission}
                name={nameof((s) => s.privateOfficeCommission)}
                onChange={this.handleInputChange}
                onKeyDown={this.onKeyDownForNumberInput}
                errors={this.getFieldErrors(
                  nameof((s) => s.privateOfficeCommission)
                )}
              />
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.businessContactInformation}
              </div>
              <div className="description">
                {AddOrEditWorkspaceForm.Resources.completeBsnContactInfo}
              </div>
            </div>
            <div className="line-action">
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.emailAddress}
                inputType={EntityFieldInputType.Text}
                className={"form-control"}
                value={this.state.businessEmail}
                name={nameof((s) => s.businessEmail)}
                onChange={this.handleInputChange}
                errors={this.getFieldErrors(nameof((s) => s.businessEmail))}
              />

              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.phoneNumber}
                inputType={EntityFieldInputType.Text}
                className={"form-control"}
                value={this.state.businessPhone}
                name={nameof((s) => s.businessPhone)}
                onChange={this.handleInputChange}
                errors={this.getFieldErrors(nameof((s) => s.businessPhone))}
              />
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.metaInformation}
              </div>
            </div>
            <div className="line-action">
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.metaTitle}
                inputType={EntityFieldInputType.Text}
                className={"form-control"}
                value={this.state.metaTitle}
                name={nameof((s) => s.metaTitle)}
                onChange={this.handleInputChange}
                errors={this.getFieldErrors(nameof((s) => s.metaTitle))}
              />
              <FormInput
                placeholder={AddOrEditWorkspaceForm.Resources.metaDescription}
                inputType={EntityFieldInputType.Text}
                className={"form-control"}
                value={this.state.metaDescription}
                name={nameof((s) => s.metaDescription)}
                onChange={this.handleInputChange}
                errors={this.getFieldErrors(nameof((s) => s.metaDescription))}
              />
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title">
                {AddOrEditWorkspaceForm.Resources.notes}
              </div>
              <div className="description">
                {
                  AddOrEditWorkspaceForm.Resources
                    .commentsAreForInternalPurposes
                }
              </div>
            </div>
            <div className="line-action">
              <textarea
                className="form-control"
                value={this.state.notes}
                onChange={(event) => {
                  this.setState({
                    notes: event.target.value,
                  });
                }}
                placeholder={AddOrEditWorkspaceForm.Resources.notes}
              ></textarea>
            </div>
          </div>
        </div>

        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions position-sticky">
          <button
            className="btn-big btn-primary form-displayed-bottom"
            onClick={() => this.saveDetails()}
          >
            {General.Resources.save}
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        validator: AddOrEditSpaceFormValidator,
        countries: state.lookup.countries,
        cities: state.lookup.cities,
        currencies: state.lookup.currencies,
        workspaceGroups: state.lookup.workspaceGroups,
        languages: state.lookup.languages.map((op) => {
          op.selected = op.value == state.addOrEditSpace.localeId;
          return op;
        }),
        ...state.addOrEditSpace,
      };
    },
    {
      saveDetails: AddOrEditSpaceStore.actionCreators.saveDetails,
      setSpaceImages: AddOrEditSpaceStore.actionCreators.setSpaceImages,
      setSpaceSchedule: AddOrEditSpaceStore.actionCreators.setSpaceSchedule,
      setSpaceAmenities: AddOrEditSpaceStore.actionCreators.setSpaceAmenities,
    }
  )(AddOrEditSpaceForm as any)
);
