import { useState, useCallback } from "react";
import { v4 } from "uuid";

import Validations from "../../../resources/Common/Validations";
import SpacesService from "../../../services/SpacesService";
import { String_Format } from "../../../utils/utils";

export const useImageGallery = (
  initialImages: string[],
  onSaveCallback: (newOrderedImages: string[]) => void
) => {
  const [currentImages, setCurrentImages] = useState<string[]>(
    initialImages || []
  );
  const [errors, setErrors] = useState<string>("");

  const acceptedFormats = ["jpg", "jpeg", "png", "webp"];

  const getFileExtension = (filename: string) => {
    const parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const hasFileExtension = (file: File, ...acceptedExtensions: string[]) => {
    const fileExtension = getFileExtension(file.name);
    return acceptedExtensions.includes(fileExtension);
  };

  const handleImageUpload = useCallback(
    (files: File[]) => {
      const imagesToBeSaved = [];
      const newErrors = [];

      files.forEach((img) => {
        if (hasFileExtension(img, ...acceptedFormats)) {
          if (img.size / 1024 ** 2 < 2) {
            imagesToBeSaved.push({ id: v4(), file: img });
          } else {
            newErrors.push(
              String_Format(Validations.Resources.fileIsTooLarge, img.name)
            );
          }
        } else {
          newErrors.push(
            String_Format(
              Validations.Resources.theFileSelectedIsNotTheCorrectFormat,
              img.name
            )
          );
        }
      });

      setErrors(newErrors.join(", "));

      if (imagesToBeSaved.length > 0) {
        SpacesService.saveSpaceGallery(imagesToBeSaved).then((result) => {
          const newCurrentImages = [...currentImages, ...result.value];
          setCurrentImages(newCurrentImages);
          onSaveCallback(newCurrentImages);
        });
      }
    },
    [currentImages, onSaveCallback, acceptedFormats]
  );

  const uploadImagesWithInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        handleImageUpload(Array.from(e.target.files));
      }
    },
    [handleImageUpload]
  );

  const uploadImagesWithDragDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      handleImageUpload(Array.from(files));
    },
    [handleImageUpload]
  );

  const removeImage = useCallback(
    (img: string) => {
      const newCurrentImages = currentImages.filter((image) => image !== img);
      setCurrentImages(newCurrentImages);
      onSaveCallback(newCurrentImages);
    },
    [currentImages, onSaveCallback]
  );

  const changeImageOrder = useCallback(
    (newImages: string[]) => {
      setCurrentImages(newImages);
      onSaveCallback(newImages);
    },
    [onSaveCallback]
  );

  return {
    currentImages,
    errors,
    uploadImagesWithInput,
    uploadImagesWithDragDrop,
    removeImage,
    changeImageOrder,
  };
};
