import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import General from "../../resources/AdminUI/General";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import ModalDialog from "../Shared/ModalDialog";
import DeclineRoomBookingConfirmationModal from "./DeclineRoomBookingConfirmationModal";
import { RoomBookingsItem } from "../../models/RoomBookings/RoomBookingsItem";
import { ISelectListItem } from "../../models/Common/ISelectListItem";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";

interface IProps {
  roomBookings: RoomBookingsItem[];
  hiddenColumns: string[];
  showActionsColumn: boolean;
  showPendingActionsColumn: boolean;
  expandableColumns: string[];
  isRoomBookingHiddenList: boolean[];
  hasHeader: boolean;
  isDeclineModalOpen: boolean;
  selectedRoomBooking?: RoomBookingsItem;
  toggleIsExpanded: (index: number) => void;
  showDeclineRoomBookingModal: (roomBooking: RoomBookingsItem) => void;
  hideDeclineRoomBookingModal: () => void;
  declineRoomBooking: (id: number, reasonId: string) => void;
  getDeclineReasonsList: () => ISelectListItem[];
  showAcceptRoomBookingModal: (id: number) => void;
}

const RoomBookingsTableBody: FC<IProps> = (props) => {
  const navigate = useNavigate();
  const goToEditRoomBooking = (id) => {
    navigate(ApplicationPathBuilder.EditRoomBooking(id));
  };
  return (
    <tbody>
      {props.roomBookings.map((us, index) => {
        const columCount =
          us.TrueProperties.filter((p) => props.hiddenColumns.indexOf(p) < 0)
            .length + 2;

        return (
          <React.Fragment key={us.id}>
            <tr>
              <td className="btn-row-expand">
                <div onClick={() => props.toggleIsExpanded(index)}>
                  <span className="material-symbols-outlined">
                    {props.isRoomBookingHiddenList.length > 0 &&
                    props.isRoomBookingHiddenList[index]
                      ? "chevron_right"
                      : "expand_more"}
                  </span>
                </div>
              </td>
              {props.showPendingActionsColumn &&
              us.statusId.toString() ==
                RoomBookingStatuses.Pending.toString() ? (
                <td className="row-actions">
                  <div className="rooms-requests-buttons">
                    <div
                      className="btn-action decline"
                      onClick={() => props.showDeclineRoomBookingModal(us)}
                    >
                      {General.Resources.decline}
                    </div>
                    <ModalDialog
                      title={RoomBookingsGrid.Resources.declineRoomRequest}
                      open={
                        props.isDeclineModalOpen &&
                        props.selectedRoomBooking == us
                      }
                      onCancel={props.hideDeclineRoomBookingModal}
                    >
                      <DeclineRoomBookingConfirmationModal
                        onCancelCallback={props.hideDeclineRoomBookingModal}
                        onSaveCallback={props.declineRoomBooking}
                        id={us.id}
                        reasons={props.getDeclineReasonsList()}
                      />
                    </ModalDialog>

                    <div
                      className="btn-action accept"
                      onClick={() => props.showAcceptRoomBookingModal(us.id)}
                    >
                      {General.Resources.accept}
                    </div>
                  </div>
                </td>
              ) : null}
              {props.showActionsColumn ? (
                <td className="row-actions">
                  <button
                    className="btn-table-action"
                    onClick={() => goToEditRoomBooking(us.id)}
                  >
                    <span className="material-symbols-outlined">edit</span>
                  </button>
                </td>
              ) : null}
              {us.TrueProperties.filter(
                (p) => props.hiddenColumns.indexOf(p) < 0
              ).map((p) => (
                <td
                  data-label={
                    props.hasHeader
                      ? props.roomBookings[0].GetColumnNameForProperty(p)
                      : null
                  }
                  key={p}
                >
                  {us.GetDisplayValueForProperty(p)}
                </td>
              ))}
            </tr>
            <tr
              className={
                props.isRoomBookingHiddenList.length > 0 &&
                props.isRoomBookingHiddenList[index]
                  ? "hidden-row"
                  : null
              }
            >
              {us.TrueProperties.filter(
                (p) => props.expandableColumns.indexOf(p) >= 0
              ).map((p) => (
                <td
                  className="colspan-row"
                  colSpan={columCount}
                  data-label={
                    props.hasHeader
                      ? props.roomBookings[0].GetColumnNameForProperty(p)
                      : null
                  }
                  key={p}
                >
                  {us.GetDisplayValueForProperty(p)}
                </td>
              ))}
            </tr>
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

export default RoomBookingsTableBody;
