import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { ValidatedComponent } from "../../FormValidations/Base/ValidatedComponent";
import Benefits from "../../../resources/AdminUI/Benefits/Benefits";
import bind from "bind-decorator";
import { ISelectListItem } from "../../../models/Common/ISelectListItem";
import { BenefitTypes } from "../../../enums/Benefits/BenefitTypes";
import {
  firstLetterToLowerCase,
  getPromiseFromAction,
  nameof,
} from "../../../utils/utils";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import { BenefitStartDateOptions } from "../../../enums/Benefits/BenefitStartDateOptions";
import StartDateOptions from "../../../resources/AdminUI/Benefits/StartDateOptions";
import BenefitTypeList from "../../../resources/AdminUI/Benefits/BenefitTypeList";
import { QuantityTypes } from "../../../enums/Benefits/QuantityTypes";
import General from "../../../resources/AdminUI/General";
import { IAddOrEditBenefit } from "../../../models/Benefits/Interfaces/IAddOrEditBenefit";
import { AddOrEditBenefitValidator } from "../../FormValidations/Validators/AddOrEditBenefitValidator";
import { IAntdSelectListItem } from "../../../models/Common/IAntdSelectListItem";
import { Select } from "antd";
import { AddOrEditBenefitStore } from "../../../store/AddOrEditBenefitStore";
import { NavigateFunction } from "react-router";
import { withRouter } from "../../Shared/withRouter";
import { ApplicationPathBuilder } from "../../../enums/Common/ApplicationPaths";
import Validations from "../../../resources/Common/Validations";
import { RenewalTypes } from "../../../enums/Clients/RenewalTypes";
import RenewalTypesList from "../../../resources/AdminUI/RenewalTypesList";
import Loading from "../../Shared/Loading";
import FormInput from "../../Shared/Fields/FormInput";

interface IProps {
  benefit?: IAddOrEditBenefit;
  clientId?: number;
  currencies?: IAntdSelectListItem[];
  hasServerSideErrors?: boolean;
  errors?: string;

  navigate?: NavigateFunction;

  addOrEditBenefit: (benefit: IAddOrEditBenefit) => any;
}

interface IState {
  benefit: IAddOrEditBenefit;
}

class AddOrEditBenefitForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      benefit: {
        id: this.props.benefit.id,
        name: this.props.benefit.name,
        description: this.props.benefit.description,
        featureId: this.props.benefit.featureId,
        startFromCurrentCycle: true,
        isUnlimited: this.props.benefit.isUnlimited,
        value: this.props.benefit.value,
        currency: this.props.benefit.currency,
        userUsageLimit: this.props.benefit.userUsageLimit,
        hasUserLimit: this.props.benefit.hasUserLimit,
        renewMaxCycles: this.props.benefit.renewMaxCycles,
        renewIntervalUnitId: this.props.benefit.renewIntervalUnitId,
        renewIntervalValue: this.props.benefit.renewIntervalValue,
        companyId: this.props.benefit.companyId,
        subscriptionId: this.props.benefit.subscriptionId,
        subscriptionStartsAt: this.props.benefit.subscriptionStartsAt,
        costPerEntry: this.props.benefit.costPerEntry,
        isPromo: this.props.benefit.isPromo,
      },
    };
  }

  @bind
  getBenefitType() {
    let benefitTypesList = [];

    const keys = Object.keys(BenefitTypes).filter(
      (key) => typeof BenefitTypes[key] === "number"
    );

    keys.forEach((key) => {
      let value = BenefitTypes[key].toString();
      benefitTypesList.push({
        value: value,
        label: BenefitTypeList.Resources[firstLetterToLowerCase(key)],
      } as IAntdSelectListItem);
    });

    return benefitTypesList;
  }

  @bind
  getBenefitStartDateOptions() {
    let benefitStartDateOptions = [];

    const keys = Object.keys(BenefitStartDateOptions).filter(
      (key) => typeof BenefitStartDateOptions[key] === "number"
    );

    keys.forEach((key) => {
      let value = BenefitStartDateOptions[key].toString();
      benefitStartDateOptions.push({
        value: value,
        text: StartDateOptions.Resources[firstLetterToLowerCase(key)],
      } as ISelectListItem);
    });

    return benefitStartDateOptions;
  }

  @bind
  getQuantityTypesOptions() {
    let quantityOptions = [];

    const keys = Object.keys(QuantityTypes).filter(
      (key) => typeof QuantityTypes[key] === "number"
    );
    keys.forEach((key) => {
      let value = QuantityTypes[key].toString();
      quantityOptions.push({
        value: value,
        text:
          value == QuantityTypes.Unlimited
            ? key
            : this.state.benefit.featureId != BenefitTypes.RoomBudget
            ? Benefits.Resources.limitedEntries
            : Benefits.Resources.limitedBugdet,
        selected:
          value ==
          (this.state.benefit.isUnlimited.valueOf()
            ? QuantityTypes.Unlimited
            : QuantityTypes.LimitedEntries),
      } as ISelectListItem);
    });

    return quantityOptions;
  }

  @bind
  getUserLimitQuantityTypesOptions() {
    let quantityOptions = [];

    const keys = Object.keys(QuantityTypes).filter(
      (key) => typeof QuantityTypes[key] === "number"
    );
    var selectedValue =
      this.state.benefit.hasUserLimit &&
      this.state.benefit.hasUserLimit.valueOf()
        ? QuantityTypes.LimitedEntries.toString()
        : QuantityTypes.Unlimited.toString();
    keys.forEach((key) => {
      let value = QuantityTypes[key].toString();
      quantityOptions.push({
        value: value,
        text:
          value == QuantityTypes.Unlimited
            ? key
            : this.state.benefit.featureId != BenefitTypes.RoomBudget
            ? Benefits.Resources.limitedEntries
            : Benefits.Resources.limitedBugdet,
        selected: value == selectedValue,
      } as ISelectListItem);
    });

    return quantityOptions;
  }

  @bind
  getRenewalTypes() {
    let renewalOptions: IAntdSelectListItem[] = [];

    const keys = Object.keys(RenewalTypes).filter(
      (k) => typeof RenewalTypes[k] === "number"
    );

    keys.forEach((key) => {
      let value = RenewalTypes[key].toString();
      renewalOptions.push({
        value: value,
        label: RenewalTypesList.Resources[firstLetterToLowerCase(key)],
      } as IAntdSelectListItem);
    });

    return renewalOptions;
  }

  @bind
  handleOnInputChange(name: string, data: any) {
    this.setState({
      benefit: {
        ...this.state.benefit,
        [name]: data,
      },
    });
  }

  @bind
  handleOnStartDateInputChange(name: string, data: any) {
    this.setState({
      benefit: {
        ...this.state.benefit,
        [name]: data == BenefitStartDateOptions.StartImmediately ? true : false,
      },
    });
  }

  @bind
  handleOnQuantityInputChange(name: string, data: any) {
    this.setState({
      benefit: {
        ...this.state.benefit,
        [name]: data == QuantityTypes.Unlimited.toString() ? true : false,
        value:
          data == QuantityTypes.Unlimited.toString()
            ? null
            : this.state.benefit.value,
        currency:
          data == QuantityTypes.Unlimited.toString()
            ? null
            : this.state.benefit.currency,
      },
    });
  }

  @bind
  handleOnQuantityValueInputChange(name: string, data: any) {
    this.setState({
      benefit: {
        ...this.state.benefit,
        [name]:
          this.state.benefit.featureId == BenefitTypes.RoomBudget
            ? data * 100
            : data,
      },
    });
  }

  @bind
  onKeyDownForNumberInput(e: any) {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  handleOnSaveButtonClick() {
    this.validate(() => {
      getPromiseFromAction(
        this.props.addOrEditBenefit(this.state.benefit)
      ).then(() => {
        this.props.navigate(
          ApplicationPathBuilder.ViewCustomerDetails(
            this.props.benefit.companyId
          )
        );
      });
    });
  }
  @bind
  handleOnCancleButtonClick() {
    this.props.navigate(
      ApplicationPathBuilder.ViewCustomerDetails(this.props.benefit.companyId)
    );
  }

  render() {
    if (!this.props.currencies) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div className="form-container">
        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {Benefits.Resources.serviceTypeLabel}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <div className="input-group form-select">
              <Select
                options={this.getBenefitType()}
                onChange={(value: any, option: any) => {
                  this.handleOnInputChange(
                    nameof((s) => s.featureId),
                    value
                  );
                }}
                value={this.state.benefit.featureId?.toString()}
                placeholder={General.Resources.select}
                disabled={!!this.props.benefit.id}
              ></Select>
            </div>
            {this.getFieldErrors(nameof((s) => s.featureId)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">{Benefits.Resources.promoTypeLabel}</div>
          </div>
          <div className="line-action checkbox">
            <div>
              <input
                disabled={!!this.props.benefit.id}
                type="checkbox"
                name={nameof((s) => s.isPromo)}
                checked={this.state.benefit.isPromo || false}
                onChange={(event) => {
                  this.setState({
                    benefit: {
                      ...this.state.benefit,
                      isPromo: event.target.checked,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {Benefits.Resources.titleLabel}
              <span className="mandatory">*</span> &{" "}
              {Benefits.Resources.descLabel}
            </div>
            <div className="description">
              {Benefits.Resources.titleDescExplaination}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={Benefits.Resources.completeTitleText}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.benefit.name}
              onChange={this.handleOnInputChange}
              errors={this.getFieldErrors(nameof((e) => e.name))}
              name={nameof((e) => e.name)}
            />
            <FormInput
              placeholder={Benefits.Resources.completeDescText}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.benefit.description}
              onChange={this.handleOnInputChange}
              errors={this.getFieldErrors(nameof((e) => e.description))}
              name={nameof((e) => e.description)}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {Benefits.Resources.quantityLabel}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {Benefits.Resources.quantityDescLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              options={this.getQuantityTypesOptions()}
              inputType={EntityFieldInputType.Radio}
              className={"form-control room_type_radio_box"}
              name={nameof((e) => e.isUnlimited)}
              onChange={this.handleOnQuantityInputChange}
              errors={this.getFieldErrors(nameof((e) => e.isUnlimited))}
              value={
                this.state.benefit.isUnlimited.valueOf()
                  ? QuantityTypes.Unlimited
                  : QuantityTypes.LimitedEntries
              }
            />

            {!this.state.benefit.isUnlimited.valueOf() && (
              <FormInput
                placeholder={Benefits.Resources.completeServiceQuantityText}
                inputType={EntityFieldInputType.Number}
                className={"form-control"}
                name={nameof((e) => e.value)}
                onChange={this.handleOnQuantityValueInputChange}
                errors={this.getFieldErrors(nameof((e) => e.value))}
                value={
                  this.state.benefit.featureId == BenefitTypes.RoomBudget
                    ? this.state.benefit.value / 100
                    : this.state.benefit.value
                }
              />
            )}

            {this.state.benefit.featureId == BenefitTypes.RoomBudget && (
              <div>
                <Select
                  options={this.props.currencies}
                  onChange={(value, option) => {
                    this.handleOnInputChange(
                      nameof((e) => e.currency),
                      value
                    );
                  }}
                  value={this.state.benefit.currency?.toString()}
                  showSearch
                  placeholder={Benefits.Resources.selectCurrency}
                  optionFilterProp="label"
                ></Select>
                {this.getFieldErrors(nameof((s) => s.currency)).map((err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>

        {this.state.benefit.featureId != BenefitTypes.RoomBudget && (
          <div className="form-line">
            <div className="line-info">
              <div className="title">{Benefits.Resources.costPerEntry}</div>
              <div className="description">
                {Benefits.Resources.optionalText}
              </div>
            </div>
            <div className="line-action">
              <FormInput
                placeholder={Benefits.Resources.costPerEntry}
                inputType={EntityFieldInputType.Text}
                className={"form-control"}
                value={this.state.benefit.costPerEntry}
                name={nameof((s) => s.costPerEntry)}
                onChange={this.handleOnInputChange}
                errors={this.getFieldErrors(nameof((s) => s.costPerEntry))}
                onKeyDown={this.onKeyDownForNumberInput}
              />
            </div>
          </div>
        )}

        <h2 className="form-divider">
          {Benefits.Resources.renewalDetailsText}
        </h2>

        {!this.props.benefit.id && (
          <div>
            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {Benefits.Resources.renewalTypeLabel}
                  <span className="mandatory">*</span> &{" "}
                  {General.Resources.value}
                  <span className="mandatory">*</span>
                </div>
              </div>

              <div className="line-action inline-inputs">
                <div className="input-group">
                  <span className="input-group-text">
                    {General.Resources.every}
                  </span>
                  <input
                    type={EntityFieldInputType.Number}
                    placeholder={Benefits.Resources.completeRenewalValue}
                    className={`form-control`}
                    onChange={(e) =>
                      this.handleOnInputChange(
                        e.currentTarget.name,
                        e.currentTarget.value
                      )
                    }
                    name={nameof((s) => s.renewIntervalValue)}
                    onKeyDown={this.onKeyDownForNumberInput}
                    value={this.state.benefit.renewIntervalValue || ""}
                  />
                </div>
                <div className="input-group form-select">
                  <Select
                    options={this.getRenewalTypes()}
                    onChange={(value: any, option: any) => {
                      this.handleOnInputChange(
                        nameof((s) => s.renewIntervalUnitId),
                        value
                      );
                    }}
                    value={this.state.benefit.renewIntervalUnitId?.toString()}
                    placeholder={Benefits.Resources.selectRenewalType}
                    disabled={!!this.props.benefit.id}
                  ></Select>
                </div>

                <div className="break" />

                {this.getFieldErrors(nameof((s) => s.renewIntervalValue)).map(
                  (err) => (
                    <span key={err} className="field-validation-error">
                      {err}
                    </span>
                  )
                )}

                {this.getFieldErrors(nameof((s) => s.renewIntervalUnitId)).map(
                  (err) => (
                    <span key={err} className="field-validation-error">
                      {err}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        )}

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {Benefits.Resources.maxRenewalCyclelabel}
            </div>
            <div className="description">{Benefits.Resources.optionalText}</div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={Benefits.Resources.maxRenewalCyclelabel}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.benefit.renewMaxCycles}
              name={nameof((s) => s.renewMaxCycles)}
              onChange={this.handleOnInputChange}
              errors={this.getFieldErrors(nameof((s) => s.renewMaxCycles))}
              onKeyDown={this.onKeyDownForNumberInput}
            />
          </div>
        </div>

        <h2 className="form-divider">{Benefits.Resources.restrictionsLabel}</h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">{Benefits.Resources.maxUsePerUserLabel}</div>
            <div className="description">{Benefits.Resources.optionalText}</div>
          </div>
          <div className="line-action">
            <FormInput
              options={this.getUserLimitQuantityTypesOptions()}
              inputType={EntityFieldInputType.Radio}
              className={"form-control room_type_radio_box"}
              name={nameof((e) => e.hasUserLimit)}
              onChange={(name: string, data: any) => {
                this.setState({
                  benefit: {
                    ...this.state.benefit,
                    [name]: data != QuantityTypes.Unlimited.toString(),
                    userUsageLimit:
                      data != QuantityTypes.Unlimited.toString()
                        ? this.state.benefit.userUsageLimit
                        : null,
                  },
                });
              }}
              errors={this.getFieldErrors(nameof((e) => e.hasUserLimit))}
              value={
                this.state.benefit.hasUserLimit &&
                this.state.benefit.hasUserLimit.valueOf()
                  ? QuantityTypes.LimitedEntries
                  : QuantityTypes.Unlimited
              }
            />

            {this.state.benefit.hasUserLimit &&
              this.state.benefit.hasUserLimit.valueOf() && (
                <FormInput
                  placeholder={Benefits.Resources.maxUsePerUserLabel}
                  inputType={EntityFieldInputType.Number}
                  className={"form-control"}
                  value={
                    this.state.benefit.featureId == BenefitTypes.RoomBudget
                      ? this.state.benefit.userUsageLimit / 100
                      : this.state.benefit.userUsageLimit
                  }
                  name={nameof((s) => s.userUsageLimit)}
                  onChange={this.handleOnQuantityValueInputChange}
                  errors={this.getFieldErrors(nameof((s) => s.userUsageLimit))}
                  onKeyDown={this.onKeyDownForNumberInput}
                />
              )}
          </div>
        </div>

        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-secondary"
            onClick={this.handleOnCancleButtonClick}
          >
            {General.Resources.cancelButtonLabel}
          </button>
          <button
            className="btn-big btn-primary"
            onClick={this.handleOnSaveButtonClick}
          >
            {this.props.benefit.id
              ? General.Resources.save
              : Benefits.Resources.createServiceText}
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        validator: AddOrEditBenefitValidator,
        ownProps,
        currencies: state.lookup.currencies,
        hasServerSideErrors: state.benefits.hasServerSideErrors,
        errors: state.benefits.errors,
        benefit: state.addOrEditBenefit.benefit,
      };
    },
    {
      addOrEditBenefit: AddOrEditBenefitStore.actionCreators.addOrEditBenefit,
    }
  )(AddOrEditBenefitForm as any)
);
