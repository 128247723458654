import bind from "bind-decorator";
import { BenefitBeneficiaries } from "../../../enums/Benefits/BenefitBeneficiaries";
import { BenefitStartDateOptions } from "../../../enums/Benefits/BenefitStartDateOptions";
import { ISelectListItem } from "../../../models/Common/ISelectListItem";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import { getServerSideErrors, nameof } from "../../../utils/utils";
import { ValidatedComponent } from "../../FormValidations/Base/ValidatedComponent";
import AssignBenefitToTeamOrUser from "../../../resources/AdminUI/Benefits/AssignBenefitToTeamOrUser";
import BenefitService from "../../../services/BenefitsService";
import General from "../../../resources/AdminUI/General";
import Result from "../../../models/Common/Result";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { AssignBenefitValidator } from "../../FormValidations/Validators/AssignBenefitValidator";
import Validations from "../../../resources/Common/Validations";
import { TeamsStore } from "../../../store/TeamsStore";
import Loading from "../../Shared/Loading";
import FormInput from "../../Shared/Fields/FormInput";

interface IProps {
  clientId: number;
  benefitId: number;
  benefitBeneficiaryList: ISelectListItem[];
  benefitStartDateOptions: ISelectListItem[];
  getTeams?: (id: number) => void;
  onCancelCallback?: () => void;
}

interface IState {
  benefitBeneficiary?: string;
  benefitStartDateOption?: string;
  teamIds?: number[];
  userIds?: number[];
  teams?: ISelectListItem[];
  users?: ISelectListItem[];
  errorsFromGetTeamsAndUsers?: string;
  serverSideErrors?: string;
}

class AssignBenefitForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      benefitBeneficiary: BenefitBeneficiaries.Teams.toString(),
      benefitStartDateOption:
        BenefitStartDateOptions.StartImmediately.toString(),
      teams: null,
      users: null,
      teamIds: [],
      userIds: [],
    };

    this.getCompanyTeamsAndUsersForAssignBenefit(
      this.props.clientId,
      this.props.benefitId
    );
  }

  @bind
  async getCompanyTeamsAndUsersForAssignBenefit(
    clientId: number,
    benefitId: number
  ) {
    await BenefitService.getCompanyTeamsAndUsersForAssignBenefit(
      clientId,
      benefitId
    ).then((res) => {
      var errors = getServerSideErrors(res);
      var hasServerSideErrors = errors ? true : false;
      if (hasServerSideErrors) {
        this.setState({
          errorsFromGetTeamsAndUsers: errors,
        });
      } else {
        this.setState({
          teams: res.value.teams,
          users: res.value.users,
        });
      }
    });
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      [name]: data,
    });
  }

  @bind
  onMultipleSelectChange(name: string, data: ISelectListItem[]) {
    var selectedIds = data.map((item) => parseInt(item.value));
    this.setState({
      [name]: selectedIds,
    });
  }

  @bind
  async onSave() {
    this.validateAsync(() => {
      if (
        this.state.benefitBeneficiary == BenefitBeneficiaries.Teams.toString()
      ) {
        BenefitService.assignBenefitToTeams(
          this.props.benefitId,
          this.state.teamIds,
          parseInt(this.state.benefitStartDateOption)
        ).then((res) => {
          this.props.getTeams(this.props.clientId);
          this.onSaveCallback(res);
        });
      }
      if (
        this.state.benefitBeneficiary == BenefitBeneficiaries.Users.toString()
      ) {
        BenefitService.assignBenefitToUsers(
          this.props.benefitId,
          this.state.userIds,
          parseInt(this.state.benefitStartDateOption)
        ).then((res) => {
          this.onSaveCallback(res);
        });
      }
    });
  }

  @bind
  onSaveCallback(res: Result<[]>) {
    var errors = getServerSideErrors(res);
    var hasServerSideErrors = errors ? true : false;
    if (hasServerSideErrors) {
      this.setState({
        serverSideErrors: errors,
      });
    } else {
      this.props.onCancelCallback();
    }
  }

  render() {
    if (this.state.errorsFromGetTeamsAndUsers) {
      return (
        <div>
          {this.state.errorsFromGetTeamsAndUsers ? (
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.state.errorsFromGetTeamsAndUsers}
            </span>
          ) : (
            <></>
          )}
        </div>
      );
    }
    if (!this.state.users || !this.state.teams) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div className="modal-container">
        <div className="modal-title">
          {AssignBenefitToTeamOrUser.Resources.assignServiceToTeamOrUserTitle}
        </div>

        <div className="modal-description">
          {AssignBenefitToTeamOrUser.Resources.assignServiceDesc}
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {AssignBenefitToTeamOrUser.Resources.fromWhomDoYouAssign}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              inputType={EntityFieldInputType.Radio}
              options={this.props.benefitBeneficiaryList.map((op) => {
                op.selected =
                  this.state.benefitBeneficiary == op.value.toString();
                return op;
              })}
              className={"check-group"}
              name={nameof((e) => e.benefitBeneficiary)}
              value={this.state.benefitBeneficiary}
              onChange={(name: string, data: any) => {
                this.setState({
                  errors: [],
                  teamIds: [],
                  userIds: [],
                });
                this.handleInputChange(name, data);
              }}
              errors={this.getFieldErrors(nameof((e) => e.benefitBeneficiary))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {AssignBenefitToTeamOrUser.Resources.chooseBeneficiaries}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action asign-benefits-lists-container">
            <div className="asign-benefits-scrollable-list">
              {this.state.benefitBeneficiary ==
              BenefitBeneficiaries.Teams.toString() ? (
                this.state.teams.length > 0 ? (
                  <FormInput
                    inputType={EntityFieldInputType.Checkbox}
                    options={this.state.teams}
                    className={"check-group"}
                    name={nameof((e) => e.teamIds)}
                    value={this.state.teamIds}
                    onChange={this.onMultipleSelectChange}
                    errors={this.getFieldErrors(nameof((e) => e.teamIds))}
                  />
                ) : (
                  <div></div>
                )
              ) : null}
              {this.state.benefitBeneficiary ==
              BenefitBeneficiaries.Users.toString() ? (
                <FormInput
                  inputType={EntityFieldInputType.Checkbox}
                  options={this.state.users}
                  className={"check-group"}
                  name={nameof((e) => e.userIds)}
                  value={this.state.userIds}
                  onChange={this.onMultipleSelectChange}
                  errors={this.getFieldErrors(nameof((e) => e.userIds))}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {AssignBenefitToTeamOrUser.Resources.applyFromWhen}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              inputType={EntityFieldInputType.Radio}
              options={this.props.benefitStartDateOptions.map((op) => {
                op.selected =
                  this.state.benefitStartDateOption == op.value.toString();
                return op;
              })}
              className={"check-group"}
              name={nameof((e) => e.benefitStartDateOption)}
              value={this.state.benefitStartDateOption}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(
                nameof((e) => e.benefitStartDateOption)
              )}
            />
          </div>
        </div>

        {this.state.serverSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.state.serverSideErrors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-secondary"
            onClick={this.props.onCancelCallback}
          >
            {General.Resources.cancelButtonLabel}
          </button>
          <button className="btn-big btn-primary" onClick={this.onSave}>
            {General.Resources.save}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: IProps) => {
    return {
      validator: AssignBenefitValidator,
      ...ownProps,
    };
  },
  {
    getTeams: TeamsStore.actionCreators.getTeams,
  }
)(AssignBenefitForm as any);
