// useTagButton.ts
import { useState, useRef } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { InputRef } from "antd/es/input";

interface UseTagButtonProps {
  initialTags: string[];
  onListChange?: (list: string[]) => void;
  type?: "email" | "phone" | "text";
}

export const useTagButton = ({
  initialTags,
  onListChange,
  type = "text",
}: UseTagButtonProps) => {
  const [tags, setTags] = useState<string[]>(initialTags);
  const [inputValue, setInputValue] = useState<string>("");
  const [isInputVisible, setIsInputVisible] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string>("");

  const inputRef = useRef<InputRef>(null);
  const phoneInputRef = useRef<any>(null);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleValidation(inputValue);
    }
  };

  const handleValidation = (inputTargetValue: string) => {
    if (type === "phone") {
      handleValidatePhoneNumber(inputTargetValue);
    } else if (type === "email") {
      handleValidateEmail(inputTargetValue);
    } else {
      handleInputConfirm();
    }
  };

  const handleValidatePhoneNumber = (inputTargetValue: string) => {
    if (!isValidPhoneNumber(inputTargetValue)) {
      setInputError("Invalid phone number");
    } else {
      setInputError("");
      handleInputConfirm();
    }
  };

  const handleValidateEmail = (inputTargetValue: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputTargetValue)) {
      setInputError("Invalid email address");
    } else {
      setInputError("");
      handleInputConfirm();
    }
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    onListChange && onListChange(newTags);
  };

  const showInput = () => {
    setIsInputVisible(true);
    setTimeout(() => {
      if (type === "phone") {
        phoneInputRef.current?.focus();
      } else {
        inputRef.current?.focus();
      }
    }, 0);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (inputError) {
      if (type === "email") {
        if (emailRegex.test(e.target.value)) {
          setInputError("");
        }
      } else {
        setInputError("");
      }
    }
  };

  const handlePhoneInputChange = (value: string) => {
    setInputValue(value);
    if (inputError && isValidPhoneNumber(value)) {
      setInputError("");
    }
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      const newTags = [...tags, inputValue];
      setTags(newTags);
      onListChange && onListChange(newTags);
    }
    setIsInputVisible(false);
    setInputValue("");
    setInputError("");
  };

  const handleBlur = () => {
    handleValidation(inputValue);
  };

  return {
    tags,
    inputValue,
    isInputVisible,
    inputError,
    inputRef,
    phoneInputRef,
    handleKeyPress,
    handleValidation,
    handleClose,
    showInput,
    handleInputChange,
    handlePhoneInputChange,
    handleInputConfirm,
    handleBlur,
  };
};
