import React, { CSSProperties, forwardRef, useState, FC } from "react";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  DragEndEvent,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { v4 } from "uuid";

interface ItemProps {
  children: React.ReactNode;
  style?: CSSProperties;
  ref?: React.Ref<HTMLDivElement>;
}

const Item: FC<ItemProps> = forwardRef<HTMLDivElement, ItemProps>(
  ({ children, ...props }, ref) => (
    <div {...props} ref={ref}>
      {children}
    </div>
  )
);

interface SortableItemProps {
  getItemId: (item: any) => string | number;
  item: any;
  style?: CSSProperties;
  getGridElementInnerContent: (item: any, listeners: any) => React.ReactNode;
}

const SortableItem: FC<SortableItemProps> = ({
  getItemId,
  item,
  style,
  getGridElementInnerContent,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: getItemId(item),
      resizeObserverConfig: { disabled: true },
    });

  const itemStyle: CSSProperties = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Item ref={setNodeRef} style={itemStyle} {...attributes}>
      {getGridElementInnerContent(item, listeners)}
    </Item>
  );
};

interface DnDGridProps {
  items: any[];
  onChange: (newItems: any[]) => void;
  getGridElementInnerContent: (item: any, listeners: any) => React.ReactNode;
  getItemId: (item: any) => string | number;
  itemStyle?: CSSProperties;
}

const DnDGrid: FC<DnDGridProps> = ({
  items,
  onChange,
  getGridElementInnerContent,
  getItemId,
  itemStyle,
}) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [activeId, setActiveId] = useState<string | null>(null);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((i) => active.id === getItemId(i));
      const newIndex = items.findIndex((i) => over?.id === getItemId(i));
      const newItems = arrayMove(items, oldIndex, newIndex);
      onChange(newItems);
    }
    setActiveId(null);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id as string);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={items.map((i) => getItemId(i))}
        strategy={rectSortingStrategy}
      >
        {items.map((item) => (
          <SortableItem
            key={v4()}
            item={item}
            getGridElementInnerContent={getGridElementInnerContent}
            getItemId={getItemId}
            style={itemStyle}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default DnDGrid;
