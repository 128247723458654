import React from "react";
import General from "../../../resources/AdminUI/General";
import bind from "bind-decorator";
import { BenefitDeactivateOptions } from "../../../enums/Benefits/BenefitDeactivateOptions";
import { firstLetterToLowerCase, nameof } from "../../../utils/utils";
import { ISelectListItem } from "../../../models/Common/ISelectListItem";
import DeactivateOptions from "../../../resources/AdminUI/Benefits/DeactivateOptions";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import Benefits from "../../../resources/AdminUI/Benefits/Benefits";
import FormInput from "../../Shared/Fields/FormInput";

interface IProps {
  benefitId: number;
  contentText: string;
  confirmButtonText: string;
  onCancelCallback: () => void;
  onSaveCallback: (
    benefitId: number,
    deactivateFromCurrentCycle: boolean
  ) => void;
}

interface IState {
  deactivateFromCurrentCycle: boolean;
}

export class DeactivateBenefitForm extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      deactivateFromCurrentCycle: true,
    };
  }

  @bind
  getBenefitDeactivateOptions() {
    let benefitStartDateOptions = [];

    const keys = Object.keys(BenefitDeactivateOptions).filter(
      (key) => typeof BenefitDeactivateOptions[key] === "number"
    );

    keys.forEach((key) => {
      let value = BenefitDeactivateOptions[key].toString();
      benefitStartDateOptions.push({
        value: value,
        text: DeactivateOptions.Resources[firstLetterToLowerCase(key)],
        selected: value == "1",
      } as ISelectListItem);
    });

    return benefitStartDateOptions;
  }

  @bind
  handleOnInputChange(name, data) {
    this.setState({
      deactivateFromCurrentCycle:
        data == BenefitDeactivateOptions.DeactivateImmediately,
    });
  }

  render() {
    return (
      <div className="modal-container">
        <div className="modal-title">
          {General.Resources.areYouSureModalLabel}
        </div>
        <div className="modal-description">{this.props.contentText}</div>

        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {Benefits.Resources.whenToDeactivateLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              options={this.getBenefitDeactivateOptions()}
              inputType={EntityFieldInputType.Radio}
              className={"form-control"}
              name={nameof((e) => e.startFromCurrentCycle)}
              onChange={this.handleOnInputChange}
              value={
                this.state.deactivateFromCurrentCycle
                  ? BenefitDeactivateOptions.DeactivateImmediately
                  : BenefitDeactivateOptions.DeactivateNextCycle
              }
            />
          </div>
        </div>

        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-secondary"
            onClick={this.props.onCancelCallback}
          >
            {General.Resources.cancelButtonLabel}
          </button>
          <button
            className="btn-big btn-primary"
            onClick={() =>
              this.props.onSaveCallback(
                this.props.benefitId,
                this.state.deactivateFromCurrentCycle
              )
            }
          >
            {this.props.confirmButtonText}
          </button>
        </div>
      </div>
    );
  }
}
