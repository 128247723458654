import React, { useRef, useEffect, useState } from "react";

interface TextInputProps {
  label?: string;
  leadingText?: string;
  className?: string;
  errors?: string[];
  value?: any;
  readOnly?: boolean;
  hidden?: boolean;
  isFocused?: boolean;
  cursorPosition?: number;
  onChange?: (data: any, cursorPosition?: number) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  name?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  leadingText,
  className,
  errors = [],
  value,
  readOnly,
  hidden,
  isFocused,
  cursorPosition,
  onChange,
  onKeyDown,
  placeholder,
  name,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
      if (inputRef.current) {
        inputRef.current.selectionEnd = cursorPosition;
      }
    }
  }, [isFocused, cursorPosition]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    onChange?.(newValue, e.target.selectionEnd || undefined);
  };

  return (
    <div className="form-item">
      {label && <label>{label}</label>}
      <div className="input-group">
        {leadingText && <span className="input-group-text">{leadingText}</span>}
        <input
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          className={`${className} ${
            errors.length > 0 ? "input-validation-error" : ""
          }`}
          onChange={readOnly ? undefined : handleInputChange}
          name={name}
          value={localValue || ""}
          readOnly={readOnly}
          hidden={hidden}
          onKeyDown={onKeyDown}
        />
      </div>
      {errors.map((err) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default TextInput;
