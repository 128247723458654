import React from "react";

import { ISelectListItem } from "../../../models/Common/ISelectListItem";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import CheckboxInput from "./CheckboxInput";
import DateInput from "./DateInput";
import FileInput from "./FileInput";
import ImageInput from "./ImageInput";
import RadioInput from "./RadioInput";
import TextInput from "./TextInput";

interface IProps {
  inputType?: EntityFieldInputType;
  placeholder?: string;
  label?: string;
  leadingText?: string;
  className?: string;
  errors?: string[];
  options?: ISelectListItem[];
  value?: any;
  readOnly?: boolean;
  hidden?: boolean;
  name?: string;
  isDataLoading?: boolean;
  isFocused?: boolean;
  cursorPosition?: number;
  onChange?: (propName: string, data: any, cursorPosition?: number) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  refreshData?: () => any;
}

const FormInput: React.FC<IProps> = ({
  inputType = EntityFieldInputType.Text,
  placeholder,
  label,
  leadingText,
  className,
  errors = [],
  options,
  value,
  readOnly,
  hidden,
  refreshData,
  isDataLoading,
  isFocused,
  cursorPosition,
  onChange,
  onKeyDown,
  name,
}) => {
  const handleChange = (newValue: any, cursorPos?: number) => {
    onChange?.(name, newValue, cursorPos);
  };

  switch (inputType) {
    case EntityFieldInputType.Text:
    case EntityFieldInputType.Number:
    case EntityFieldInputType.Password:
      return (
        <TextInput
          label={label}
          leadingText={leadingText}
          className={className}
          errors={errors}
          value={value}
          readOnly={readOnly}
          hidden={hidden}
          isFocused={isFocused}
          cursorPosition={cursorPosition}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          name={name}
        />
      );

    case EntityFieldInputType.File:
      return (
        <FileInput
          label={label}
          className={className}
          errors={errors}
          value={value}
          readOnly={readOnly}
          hidden={hidden}
          onChange={handleChange}
          placeholder={placeholder}
          name={name}
        />
      );

    case EntityFieldInputType.Image:
      return (
        <ImageInput
          label={label}
          className={className}
          errors={errors}
          value={value}
          readOnly={readOnly}
          hidden={hidden}
          onChange={handleChange}
          placeholder={placeholder}
          name={name}
        />
      );

    case EntityFieldInputType.Radio:
      return (
        <RadioInput
          label={label}
          className={className}
          errors={errors}
          options={options}
          value={value}
          readOnly={readOnly}
          hidden={hidden}
          onChange={handleChange}
          name={name}
          refreshData={refreshData}
          isDataLoading={isDataLoading}
        />
      );

    case EntityFieldInputType.Date:
      return (
        <DateInput
          label={label}
          className={className}
          errors={errors}
          value={value}
          readOnly={readOnly}
          hidden={hidden}
          onChange={handleChange}
          name={name}
        />
      );

    case EntityFieldInputType.Checkbox:
      return (
        <CheckboxInput
          label={label}
          className={className}
          errors={errors}
          options={options}
          value={value}
          readOnly={readOnly}
          hidden={hidden}
          onChange={handleChange}
          name={name}
          refreshData={refreshData}
          isDataLoading={isDataLoading}
        />
      );

    default:
      return null;
  }
};

export default FormInput;
