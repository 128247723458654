import bind from "bind-decorator";
import React from "react";
import { connect } from "react-redux";

import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import General from "../../resources/AdminUI/General";
import { getPromiseFromAction, nameof } from "../../utils/utils";
import { EntityFieldInputType } from "../../utils/reactUtils";
import AntdDateTimePickerFormWrapper from "../Shared/Fields/AntdDateTimePickerFormWrapper";
import EditSpacePrice from "../../resources/AdminUI/Spaces/EditSpacePrice";
import { withRouter } from "../Shared/withRouter";
import { EditSpacePriceFormValidator } from "../FormValidations/Validators/EditSpacePriceFormValidator";
import FormInput from "../Shared/Fields/FormInput";

export interface IProps {
  onSave;
  hasServerSideErrors: boolean;
  errors: string;
  validator;
}

export interface IState {
  spaceId?: string;
  startDate?: string;
  fullDayPrice?: number;
  halfDayPrice?: number;
  unredeemedPrice?: number;
  vat?: number;
}

class EditSpacePriceForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      spaceId: "",
      startDate: "",
      fullDayPrice: null,
      halfDayPrice: null,
      unredeemedPrice: null,
      vat: null,
    };
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      [name]: data,
    });
  }

  @bind
  handlePriceInputChange(name: string, data: any) {
    this.setState({
      [name]: data * 100,
    });
  }

  @bind
  onKeyDownForNumberInput(e: any) {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  onSave() {
    this.validate(() => {
      getPromiseFromAction(this.props.onSave(this.state)).then(() => {
        alert("Prices saved.");
      });
    });
  }

  render() {
    return (
      <div className="form-container">
        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {EditSpacePrice.Resources.spaceId}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={EditSpacePrice.Resources.spaceId}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.spaceId}
              name={nameof((s) => s.spaceId)}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.spaceId))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {EditSpacePrice.Resources.VAT}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={EditSpacePrice.Resources.VAT}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.vat}
              name={nameof((s) => s.vat)}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.vat))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {EditSpacePrice.Resources.fullDayPrice}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={EditSpacePrice.Resources.fullDayPrice}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.fullDayPrice}
              name={nameof((s) => s.fullDayPrice)}
              onChange={this.handlePriceInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.fullDayPrice))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {EditSpacePrice.Resources.halfDayPrice}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={EditSpacePrice.Resources.halfDayPrice}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.halfDayPrice}
              name={nameof((s) => s.halfDayPrice)}
              onChange={this.handlePriceInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.halfDayPrice))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {EditSpacePrice.Resources.unredeemedPrice}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={EditSpacePrice.Resources.unredeemedPrice}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.unredeemedPrice}
              name={nameof((s) => s.unredeemedPrice)}
              onChange={this.handlePriceInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.unredeemedPrice))}
            />
          </div>
        </div>

        <AntdDateTimePickerFormWrapper
          labelTitle={EditSpacePrice.Resources.startDateTitle}
          labelSubtitle={""}
          isRequired={true}
          date={this.state.startDate}
          dateName={nameof((s) => s.startDate)}
          hideTimePicker
          errors={this.getFieldErrors(nameof((s) => s.startDate))}
          handleOnChange={this.handleInputChange}
        />

        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions position-sticky">
          <button className="btn-big btn-primary" onClick={this.onSave}>
            {General.Resources.save}
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect((state, ownProps: IProps) => {
    return {
      validator: EditSpacePriceFormValidator,
      ownProps,
    };
  }, {})(EditSpacePriceForm as any)
);
