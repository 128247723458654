import { FC, Fragment } from "react";
import { HotdeskBookingsItem } from "../../models/HotdeskBookings/HotdeskBookingsItem";

interface IProps {
  hotdeskBookings: HotdeskBookingsItem[];
  hiddenColumns: string[];
  isHotdeskBookingHiddenList: boolean[];
  showActionsColumn: boolean;
  expandableColumns: string[];
  toggleIsExpanded: (index: number) => void;
  goToEdit: (id: number) => void;
}
const HotdeskBookingsTableBody: FC<IProps> = ({
  hotdeskBookings,
  hiddenColumns,
  isHotdeskBookingHiddenList,
  showActionsColumn,
  expandableColumns,
  toggleIsExpanded,
  goToEdit,
}) => {
  return (
    <tbody>
      {hotdeskBookings.map((us, index) => {
        const columCount =
          us.TrueProperties.filter((p) => hiddenColumns.indexOf(p) < 0).length +
          2;
        return (
          <Fragment key={us.id}>
            <tr>
              <td className="btn-row-expand">
                <div onClick={() => toggleIsExpanded(index)}>
                  <span className="material-symbols-outlined">
                    {isHotdeskBookingHiddenList?.length > 0 &&
                    isHotdeskBookingHiddenList[index]
                      ? "chevron_right"
                      : "expand_more"}
                  </span>
                </div>
              </td>
              {showActionsColumn && (
                <td className="row-actions">
                  <button
                    className="btn-table-action"
                    onClick={() => goToEdit(us.id)}
                  >
                    <span className="material-symbols-outlined">edit</span>
                  </button>
                </td>
              )}
              {us.TrueProperties.filter(
                (p) => hiddenColumns.indexOf(p) < 0
              ).map((p) => (
                <td key={p}>{us.GetDisplayValueForProperty(p)}</td>
              ))}
            </tr>
            <tr
              className={
                isHotdeskBookingHiddenList?.length > 0 &&
                isHotdeskBookingHiddenList[index]
                  ? "hidden-row"
                  : undefined
              }
            >
              {us.TrueProperties.filter(
                (p) => expandableColumns.indexOf(p) >= 0
              ).map((p) => (
                <td key={p} colSpan={columCount}>
                  {us.GetDisplayValueForProperty(p)}
                </td>
              ))}
            </tr>
          </Fragment>
        );
      })}
    </tbody>
  );
};

export default HotdeskBookingsTableBody;
