import { HotdeskBookingsItem } from "../../models/HotdeskBookings/HotdeskBookingsItem";
import General from "../../resources/AdminUI/General";

interface IProps {
  showActionsColumn: boolean;
  hotdeskBookings: HotdeskBookingsItem[];
  hiddenColumns: string[];
}

const HotdeskBookingsTableHead: React.FC<IProps> = ({
  showActionsColumn,
  hiddenColumns,
  hotdeskBookings,
}) => {
  return (
    <thead>
      <tr>
        <th></th>
        {showActionsColumn && (
          <th>
            <span>{General.Resources.actions}</span>
          </th>
        )}
        {hotdeskBookings[0].TrueProperties.filter(
          (p) => hiddenColumns.indexOf(p) < 0
        ).map((p) => (
          <th key={p}>{hotdeskBookings[0].GetDisplayNameForProperty(p)}</th>
        ))}
      </tr>
    </thead>
  );
};

export default HotdeskBookingsTableHead;
