import React from "react";
import { connect } from "react-redux";
import { NavigateFunction } from "react-router";

import Benefits from "../../../resources/AdminUI/Benefits/Benefits";
import BenefitsTable from "../../Shared/BenefitsTable/BenefitsTable";
import { ApplicationState } from "../../../store";
import { BenefitsStore } from "../../../store/BenefitsStore";
import { BenefitItem } from "../../../models/Benefits/BenefitItem";
import { withRouter } from "../../Shared/withRouter";
import { ApplicationPathBuilder } from "../../../enums/Common/ApplicationPaths";
import { ISubscription } from "../../../models/Subscriptions/Interfaces/ISubscription";
import Loading from "../../Shared/Loading";

interface IProps {
  clientId: number;
  clientName: string;
  loading?: boolean;
  benefits?: BenefitItem[];

  hasServerSideErrors?: boolean;
  errors?: string;

  subscription: ISubscription;

  navigate?: NavigateFunction;

  getClientBenefits?: (clientId: number) => any;
}

interface IState {}

class BenefitsContainer extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {};

    this.props.getClientBenefits(this.props.clientId);
  }

  hiddenColumns = [
    "id",
    "value",
    "currency",
    "userUsageLimit",
    "renewIntervalUnitId",
    "nextRenewAt",
    "companyId",
    "subscriptionName",
    "voucherId",
    "startFromCurrentCycle",
    "isUnlimited",
    "renewMaxCycles",
    "hasUserLimit",
    "costPerEntry",
  ];

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div className="benefits-container">
          <div className="section-top">
            <div className="section-title">
              {Benefits.Resources.servicesHeader}
            </div>
            <button
              className="btn-big btn-primary"
              onClick={() =>
                this.props.navigate(
                  ApplicationPathBuilder.AddService(
                    this.props.clientId,
                    this.props.subscription.id
                  )
                )
              }
              disabled={!this.props.subscription.id}
            >
              {Benefits.Resources.addServiceText}
            </button>
          </div>

          {this.props.benefits.length ? (
            <BenefitsTable
              benefits={this.props.benefits}
              clientId={this.props.clientId}
              beneficiaryName={this.props.clientName}
              hiddenColumns={this.hiddenColumns}
            />
          ) : (
            <p>{Benefits.Resources.noServicesText}</p>
          )}
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        ...ownProps,
        loading: state.benefits.loading,
        benefits: state.benefits.benefits,
        hasServerSideErrors: state.benefits.hasServerSideErrors,
        errors: state.benefits.errors,
        subscription: state.subscription.subscription,
      };
    },
    {
      getClientBenefits: BenefitsStore.actionCreators.getClientBenefits,
    }
  )(BenefitsContainer as any)
);
