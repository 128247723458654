import * as React from "react";
import { Image } from "antd";

export interface IProps {
  itemUrls: string[];
}

const NewImageGalleryViewer: React.FC<IProps> = ({ itemUrls }: IProps) => {
  return (
    <div className="photographs-container">
      <div className="photographs-list-container">
        <Image.PreviewGroup>
          {itemUrls.map((url) => {
            return <Image key={url} src={url} />;
          })}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default NewImageGalleryViewer;
