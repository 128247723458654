import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { EntitiesList, IEntitiesList } from "../models/Common/IEntitiesList";
import { IRoomBookingsItem } from "../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { RoomBookingsItem } from "../models/RoomBookings/RoomBookingsItem";
import { PayloadTypes } from "../enums/Common/PayloadTypes";

export default class RoomBookingsService extends ServiceBase {
  static getRoomBookings = (
    toTake: number,
    toSkip: number,
    filters: string
  ): Promise<Result<IEntitiesList<IRoomBookingsItem>>> => {
    let url = `/odata/room-bookings/?$top=${toTake}&$skip=${toSkip}`;

    if (filters && filters.length > 0) {
      url = `${url}&$filter=${filters}`;
    }

    try {
      let result = RoomBookingsService.requestJsonFetch<
        IEntitiesList<IRoomBookingsItem>
      >({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}${url}&$orderby=Id desc&$count=true`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<IEntitiesList<IRoomBookingsItem>>>(null);
  };

  static additionalMapping = (
    entitiesList: IEntitiesList<IRoomBookingsItem>
  ): EntitiesList<IRoomBookingsItem> => {
    let newEntities = new EntitiesList<IRoomBookingsItem>();
    newEntities.entities = entitiesList.entities.map(
      (e) => new RoomBookingsItem(e)
    );
    newEntities.numberOfEntities = entitiesList.numberOfEntities;
    return newEntities;
  };

  static getRoomBookingDetailsForEdit = (id: number): Promise<Result<any>> => {
    try {
      let result = RoomBookingsService.requestJsonFetch<any>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings/${id}/form-details`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<any>>(null);
  };

  static addRoomBooking = (roomBookingDetails: any): Promise<Result<any>> => {
    let result = RoomBookingsService.sendData<any>(
      {
        method: "POST",
        url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings`,
        data: JSON.stringify(roomBookingDetails),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static updateRoomBooking = (
    id: number,
    roomBookingDetails: any
  ): Promise<Result<any>> => {
    let result = RoomBookingsService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings/${id}`,
        data: JSON.stringify(roomBookingDetails),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static deleteRoomBooking = (id: number): Promise<Result<any>> => {
    let result = RoomBookingsService.sendData<any>(
      {
        method: "DELETE",
        url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings/${id}`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static acceptRoomBooking = (id: number): Promise<Result<any>> => {
    let result = RoomBookingsService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings/${id}/accept`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static rejectRoomBooking = (
    id: number,
    reasonId: string
  ): Promise<Result<any>> => {
    let result = RoomBookingsService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings/${id}/reject/${reasonId}`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static getRoomBookingsForExport = (
    filters?: string
  ): Promise<Result<IEntitiesList<IRoomBookingsItem>>> => {
    let url = `/odata/room-bookings?$orderby=Id desc`;

    if (filters && filters.length > 0) {
      url = `${url}&$filter=${filters}`;
    }

    try {
      let result = RoomBookingsService.requestJsonFetch<
        IEntitiesList<IRoomBookingsItem>
      >({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}${url}`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<IEntitiesList<IRoomBookingsItem>>>(null);
  };

  static getNumberOfPendingRoomRequests = (): Promise<Result<number>> => {
    try {
      let result = RoomBookingsService.requestJsonFetch<number>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/room-bookings/pending/count`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<number>>(null);
  };
}
