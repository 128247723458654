import React, { FC } from "react";

import General from "../../resources/AdminUI/General";

interface IProps {
  contentTitle: string;
  contentText: string;
  confirmButtonText: string;
  onCancelCallback: () => void;
  onSaveCallback: () => void;
}

const ActionConfirmationModal: FC<IProps> = ({
  contentTitle,
  contentText,
  confirmButtonText,
  onCancelCallback,
  onSaveCallback,
}: IProps) => {
  return (
    <div className="modal-container">
      <div className="modal-title">{contentTitle}</div>
      <div className="modal-description">{contentText}</div>
      <div className="form-line form-bottom-actions">
        <button className="btn-big btn-secondary" onClick={onCancelCallback}>
          {General.Resources.cancelButtonLabel}
        </button>
        <button className="btn-big btn-primary" onClick={onSaveCallback}>
          {confirmButtonText}
        </button>
      </div>
    </div>
  );
};

export default ActionConfirmationModal;
