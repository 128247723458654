import { FC } from "react";

import { RoomBookingsItem } from "../../models/RoomBookings/RoomBookingsItem";
import General from "../../resources/AdminUI/General";

interface IProps {
  roomBookings: RoomBookingsItem[];
  hiddenColumns: string[];
  showActionsColumn: boolean;
  showPendingActionsColumn: boolean;
}

const RoomBookingsTableHead: FC<IProps> = (props) => {
  return (
    <thead>
      <tr>
        <th></th>
        {props.showActionsColumn || props.showPendingActionsColumn ? (
          <th>
            <span>{General.Resources.actions}</span>
          </th>
        ) : null}
        {props.roomBookings[0].TrueProperties.filter(
          (p) => props.hiddenColumns.indexOf(p) < 0
        ).map((p) => (
          <th key={p}>{props.roomBookings[0].GetDisplayNameForProperty(p)}</th>
        ))}
      </tr>
    </thead>
  );
};

export default RoomBookingsTableHead;
