import React, { useState } from "react";

interface ImageInputProps {
  label?: string;
  className?: string;
  errors?: string[];
  value?: any;
  readOnly?: boolean;
  hidden?: boolean;
  onChange?: (data: any) => void;
  placeholder?: string;
  name?: string;
}

const ImageInput: React.FC<ImageInputProps> = ({
  label,
  className,
  errors = [],
  value,
  readOnly,
  hidden,
  onChange,
  placeholder,
  name,
}) => {
  const [localValue, setLocalValue] = useState(value);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const uploadedImage = { formFile: file, fileUrl: url };
      setLocalValue(uploadedImage);
      onChange?.(uploadedImage);
    }
  };

  const handleImageRemove = () => {
    setLocalValue({ formFile: null, fileUrl: "" });
    onChange?.({ formFile: null, fileUrl: "" });
  };

  return (
    <div className="form-item">
      {label && <label>{label}</label>}
      <div className="form-upload">
        {localValue?.fileUrl ? (
          <div>
            <img width="250px" src={localValue.fileUrl} alt="Uploaded" />
            <span onClick={handleImageRemove}>X</span>
          </div>
        ) : (
          <div>
            <input
              type="file"
              accept="image/*"
              placeholder={placeholder}
              className={errors.length > 0 ? "input-validation-error" : ""}
              onChange={readOnly ? undefined : handleImageChange}
              name={name}
              readOnly={readOnly}
              hidden={hidden}
            />
            <div className="form-upload-wrapper">
              <label>{localValue?.formFile?.name || "No File Chosen"}</label>
            </div>
          </div>
        )}
      </div>
      {errors.map((err) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default ImageInput;
