import React from "react";
import CountriesAndCities from "../../resources/AdminUI/CountriesAndCities";
import { EntityFieldInputType } from "../../utils/reactUtils";
import { nameof } from "../../utils/utils";
import bind from "bind-decorator";
import { ICityItem } from "../../models/CountriesAndCities/ICityItem";
import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { AddCityValidator } from "../FormValidations/Validators/AddCityValidator";
import General from "../../resources/AdminUI/General";
import Validations from "../../resources/Common/Validations";
import FormInput from "../Shared/Fields/FormInput";

interface IProps {
  countryId: number;
  city?: ICityItem;

  hasServerSideErrors?: boolean;
  errors?: string;
  saveCompleted?: boolean;

  onCancelCallback: () => void;
  onSaveCityCallback: (city: ICityItem) => void;
  onResetCallback: () => void;
}

interface IState {
  city: ICityItem;
}

class AddOrEditCityForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      city: {
        id: this.props.city?.id || 0,
        name: this.props.city?.name || "",
        lat: this.props.city?.lat,
        lng: this.props.city?.lng,
        timezone: this.props.city?.timezone || "",
        countryId: this.props.countryId,
      },
    };

    this.props.onResetCallback();
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      city: {
        ...this.state.city,
        [name]: data,
      },
    });
  }

  @bind
  onKeyDownForNumberInput(e: any) {
    if (["e", "E", "+"].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  handleOnSaveCity() {
    this.validate(() => {
      this.props.onSaveCityCallback(this.state.city);
    });
  }

  componentDidUpdate(
    prevProps: Readonly<IProps & IProps>,
    prevState: Readonly<IState & IState>,
    snapshot?: any
  ): void {
    if (this.props.saveCompleted) {
      this.props.onCancelCallback();
    }
  }

  render() {
    return (
      <div className="modal-container">
        <div className="modal-title">
          {CountriesAndCities.Resources.addANewCityLabel}
        </div>

        <div className="modal-description">
          {CountriesAndCities.Resources.addCityDesc}
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {CountriesAndCities.Resources.cityNameLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={CountriesAndCities.Resources.cityNameLabel}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.name)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.name))}
              value={this.state.city.name}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {CountriesAndCities.Resources.cityLatitudeLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={CountriesAndCities.Resources.cityLatitudeLabel}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              name={nameof((s) => s.lat)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.lat))}
              value={this.state.city.lat}
              onKeyDown={this.onKeyDownForNumberInput}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {CountriesAndCities.Resources.cityLongitudeLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={CountriesAndCities.Resources.cityLongitudeLabel}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              name={nameof((s) => s.lng)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.lng))}
              value={this.state.city.lng}
              onKeyDown={this.onKeyDownForNumberInput}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {CountriesAndCities.Resources.cityTimeZone}
            </div>
            <p className="description">
              {CountriesAndCities.Resources.timezoneNoteText}{" "}
              <a
                className="hyperlink-text"
                href={CountriesAndCities.Resources.timezoneLink}
                target="_blank"
              >
                link
              </a>
            </p>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={CountriesAndCities.Resources.cityTimeZone}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.timezone)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.timezone))}
              value={this.state.city.timezone}
            />
          </div>
        </div>

        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-secondary"
            onClick={this.props.onCancelCallback}
          >
            {General.Resources.cancelButtonLabel}
          </button>
          <button
            className="btn-big btn-primary"
            onClick={this.handleOnSaveCity}
          >
            {General.Resources.save}
          </button>
        </div>
      </div>
    );
  }
}

export default connect((state: ApplicationState, ownProps: IProps) => {
  return {
    ...ownProps,
    validator: AddCityValidator,
    errors: state.countriesAndCities.errors,
    hasServerSideErrors: state.countriesAndCities.hasServerSideErrors,
    saveCompleted: state.countriesAndCities.saveCompleted,
  };
}, null)(AddOrEditCityForm as any);
