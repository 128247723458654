import React from "react";

import { ISelectListItem } from "../../../models/Common/ISelectListItem";
import Loader from "../Loader";

interface IProps {
  label?: string;
  className?: string;
  errors?: string[];
  options?: ISelectListItem[];
  value?: any;
  readOnly?: boolean;
  hidden?: boolean;
  refreshData?: () => void;
  isDataLoading?: boolean;
  onChange?: (data: any) => void;
  name?: string;
}

const CheckboxInput: React.FC<IProps> = ({
  label,
  className,
  errors = [],
  options,
  value,
  readOnly,
  hidden,
  refreshData,
  isDataLoading,
  onChange,
  name,
}) => {
  if (!options || options.length === 0) {
    if (refreshData && !isDataLoading) {
      refreshData();
    }
    return <Loader show={true} />;
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedOptions = options?.map((u) => ({
      ...u,
      selected: u.value === e.currentTarget.value ? !u.selected : u.selected,
    }));

    onChange?.(updatedOptions?.filter((c) => c.selected));
  };

  return (
    <div className="form-item" hidden={hidden}>
      {label && <label>{label}</label>}
      <div className={className}>
        {options.map((ch) => (
          <label
            key={ch.value}
            style={{ display: "flex", gap: "8px" }}
            className="checkbox"
          >
            <input
              type="checkbox"
              onChange={
                readOnly || ch.disabled ? undefined : handleCheckboxChange
              }
              checked={ch.selected || ch.value === value}
              value={ch.value}
              readOnly={readOnly || ch.disabled}
              name={name}
            />
            {ch.text}
          </label>
        ))}
      </div>
      {errors.map((err) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default CheckboxInput;
