import bind from "bind-decorator";
import React from "react";
import { connect } from "react-redux";
import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import General from "../../resources/AdminUI/General";
import { getPromiseFromAction, nameof } from "../../utils/utils";
import AntdDateTimePickerFormWrapper from "../Shared/Fields/AntdDateTimePickerFormWrapper";
import { EntityFieldInputType } from "../../utils/reactUtils";
import { ApplicationState } from "../../store";
import { AddOrEditRoomBookingStore } from "../../store/AddOrEditRoomBookingStore";
import { AddOrEditRoomBookingFormValidator } from "../FormValidations/Validators/AddOrEditRoomBookingFormValidator";
import AddOrEditRoomBooking from "../../resources/AdminUI/RoomBookings/AddOrEditRoomBooking";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import { DatePicker, Select, TimePicker } from "antd";
import dayjs from "dayjs";
import { RoomBookingTypesForAddOrEdit } from "../../enums/RoomBookings/RoomBookingTypesForAddOrEdit";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import { NavigateFunction } from "react-router-dom";
import { withRouter } from "../Shared/withRouter";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import Validations from "../../resources/Common/Validations";
import FormInput from "../Shared/Fields/FormInput";

export interface IProps {
  isEditMode: boolean;
  id?: number;
  userId?: number;
  roomId?: number;
  statusId?: string;
  bookingTypeId?: string;

  confirmedAt?: string;
  startsAt?: string;
  endsAt?: string;
  cancelledAt?: string;

  confirmedAtTime?: string;
  startsAtTime?: string;
  endsAtTime?: string;
  cancelledAtTime?: string;

  statuses?: IAntdSelectListItem[];
  bookingTypes?: IAntdSelectListItem[];

  hasServerSideErrors?: boolean;
  errors?: string;

  navigate?: NavigateFunction;

  saveDetails?: (
    id: number,
    userId: number,
    roomId: number,
    statusId: string,
    bookingTypeId: string,
    confirmedAt?: string,
    startsAt?: string,
    endsAt?: string,
    cancelledAt?: string,
    confirmedAtTime?: string,
    startsAtTime?: string,
    endsAtTime?: string,
    cancelledAtTime?: string
  ) => any;
}

export interface IState {
  id?: number;
  userId?: number;
  roomId?: number;
  statusId?: string;
  bookingTypeId?: string;

  confirmedAt?: string;
  startsAt?: string;
  endsAt?: string;
  cancelledAt?: string;

  confirmedAtTime?: string;
  startsAtTime?: string;
  endsAtTime?: string;
  cancelledAtTime?: string;
}

class AddOrEditRoomBookingForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      userId: this.props.userId,
      roomId: this.props.roomId,
      statusId: this.props.statusId,
      bookingTypeId: this.props.bookingTypeId,
      confirmedAt: this.props.confirmedAt,
      startsAt: this.props.startsAt,
      endsAt: this.props.endsAt,
      cancelledAt: this.props.cancelledAt,
      confirmedAtTime: this.props.confirmedAtTime,
      startsAtTime: this.props.startsAtTime,
      endsAtTime: this.props.endsAtTime,
      cancelledAtTime: this.props.cancelledAtTime,
    };
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      [name]: data,
    });
  }

  @bind
  handleInputChangeWithAutomatedTime(name: string, data: any) {
    this.setState({
      [name]: data,
      [name + "Time"]: "00:00",
    });
  }

  @bind
  handleHourlyStartAndEndInputChange(data: any) {
    this.setState({
      startsAt: data,
      endsAt: data,
    });
  }

  @bind
  handleDailyStartAndEndInputChangeWithAutomatedTime(data: any) {
    this.setState({
      startsAt: data,
      startsAtTime: "00:00",
    });

    if (
      this.state.bookingTypeId ==
      RoomBookingTypesForAddOrEdit.DailyOneDay.toString()
    ) {
      this.setState({
        endsAt: data,
        endsAtTime: "00:00",
      });
    }
  }

  @bind
  onKeyDownForNumberInput(e: any) {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  saveDetails() {
    this.validate(() => {
      getPromiseFromAction(
        this.props.saveDetails(
          this.props.id,
          this.state.userId,
          this.state.roomId,
          this.state.statusId,
          this.state.bookingTypeId,
          this.state.confirmedAt,
          this.state.startsAt,
          this.state.endsAt,
          this.state.cancelledAt,
          this.state.confirmedAtTime,
          this.state.startsAtTime,
          this.state.endsAtTime,
          this.state.cancelledAtTime
        )
      ).then(() => {
        if (!this.props.hasServerSideErrors) {
          this.props.navigate(ApplicationPaths.RoomsLogs);
        }
      });
    });
  }

  goToRoomLogs() {
    this.props.navigate(ApplicationPaths.RoomsLogs);
  }

  render() {
    return (
      <div className="form-container">
        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditRoomBooking.Resources.userId}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditRoomBooking.Resources.userId}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.userId}
              name={nameof((s) => s.userId)}
              readOnly={this.props.isEditMode}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.userId))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditRoomBooking.Resources.roomId}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditRoomBooking.Resources.roomId}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              value={this.state.roomId}
              name={nameof((s) => s.roomId)}
              readOnly={this.props.isEditMode}
              onChange={this.handleInputChange}
              onKeyDown={this.onKeyDownForNumberInput}
              errors={this.getFieldErrors(nameof((s) => s.roomId))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditRoomBooking.Resources.status}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <div className="input-group form-select">
              <Select
                options={this.props.statuses}
                onChange={(value: string, option: any) => {
                  this.handleInputChange(
                    nameof((s) => s.statusId),
                    value
                  );
                }}
                value={this.state.statusId?.toString()}
                placeholder={General.Resources.select}
                disabled={this.props.isEditMode}
              ></Select>
            </div>
            {this.getFieldErrors(nameof((s) => s.statusId)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditRoomBooking.Resources.bookingType}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <div className="input-group form-select">
              <Select
                options={this.props.bookingTypes}
                onChange={(value: string, option: any) => {
                  if (
                    value.toString() !=
                    RoomBookingTypesForAddOrEdit.Hourly.toString()
                  ) {
                    this.setState({
                      confirmedAtTime: "00:00",
                      startsAtTime: "00:00",
                      endsAtTime: "00:00",
                      cancelledAtTime: "00:00",
                    });
                  }
                  this.handleInputChange(
                    nameof((s) => s.bookingTypeId),
                    value
                  );
                }}
                value={this.state.bookingTypeId?.toString()}
                placeholder={General.Resources.select}
                disabled={this.props.isEditMode}
              ></Select>
            </div>
            {this.getFieldErrors(nameof((s) => s.bookingTypeId)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>

        {this.state.statusId &&
        this.state.bookingTypeId &&
        (this.state.statusId == RoomBookingStatuses.Pending.toString() ||
          this.state.statusId == RoomBookingStatuses.Ended.toString() ||
          !this.props.isEditMode) ? (
          <>
            {this.state.bookingTypeId ==
            RoomBookingTypesForAddOrEdit.Hourly.toString() ? (
              <AntdDateTimePickerHourlyBookingFormWrapper
                labelTitle={AddOrEditRoomBooking.Resources.bookingInterval}
                labelSubtitle={
                  AddOrEditRoomBooking.Resources
                    .youMustChooseBasedOnSpaceTimezone
                }
                isRequired={true}
                date={this.state.startsAt}
                dateName={nameof((s) => s.startsAt)}
                startTime={this.state.startsAtTime}
                startTimeName={nameof((s) => s.startsAtTime)}
                endTime={this.state.endsAtTime}
                endTimeName={nameof((s) => s.endsAtTime)}
                isDatePickerDisabled={this.props.isEditMode}
                isTimePickerDisabled={this.props.isEditMode}
                errors={this.getFieldErrors(
                  nameof((s) => s.startsAndEndsAtDateAndTime)
                )}
                handleDateOnChange={this.handleHourlyStartAndEndInputChange}
                handleTimeOnChange={this.handleInputChange}
              />
            ) : (
              <>
                <AntdDateTimePickerFormWrapper
                  labelTitle={AddOrEditRoomBooking.Resources.bookingStartsAt}
                  labelSubtitle={
                    AddOrEditRoomBooking.Resources
                      .youMustChooseBasedOnSpaceTimezone
                  }
                  isRequired={true}
                  date={this.state.startsAt}
                  dateName={nameof((s) => s.startsAt)}
                  hideTimePicker={true}
                  isDatePickerDisabled={this.props.isEditMode}
                  isTimePickerDisabled={this.props.isEditMode}
                  errors={this.getFieldErrors(
                    nameof((s) => s.startsAtDateAndTime)
                  )}
                  handleOnChange={(name, value) =>
                    this.handleDailyStartAndEndInputChangeWithAutomatedTime(
                      value
                    )
                  }
                />
                {this.state.bookingTypeId !=
                  RoomBookingTypesForAddOrEdit.DailyOneDay.toString() && (
                  <AntdDateTimePickerFormWrapper
                    labelTitle={AddOrEditRoomBooking.Resources.bookingEndsAt}
                    labelSubtitle={
                      AddOrEditRoomBooking.Resources
                        .youMustChooseBasedOnSpaceTimezone
                    }
                    isRequired={true}
                    date={this.state.endsAt}
                    dateName={nameof((s) => s.endsAt)}
                    hideTimePicker={true}
                    isDatePickerDisabled={this.props.isEditMode}
                    isTimePickerDisabled={this.props.isEditMode}
                    errors={this.getFieldErrors(
                      nameof((s) => s.endsAtDateAndTime)
                    )}
                    handleOnChange={this.handleInputChangeWithAutomatedTime}
                  />
                )}
              </>
            )}
          </>
        ) : null}

        {this.state.statusId &&
        this.state.statusId == RoomBookingStatuses.Confirmed.toString() ? (
          <AntdDateTimePickerFormWrapper
            labelTitle={AddOrEditRoomBooking.Resources.confirmedDateAndTime}
            labelSubtitle={
              AddOrEditRoomBooking.Resources.youMustChooseBasedOnSpaceTimezone
            }
            isRequired={true}
            date={this.state.confirmedAt}
            dateName={nameof((s) => s.confirmedAt)}
            isDatePickerDisabled={this.props.isEditMode}
            isTimePickerDisabled={this.props.isEditMode}
            hideTimePicker={true}
            errors={this.getFieldErrors(
              nameof((s) => s.confirmedAtDateAndTime)
            )}
            handleOnChange={this.handleInputChangeWithAutomatedTime}
          />
        ) : null}

        {this.state.statusId &&
        this.state.statusId == RoomBookingStatuses.Cancelled.toString() ? (
          <AntdDateTimePickerFormWrapper
            labelTitle={AddOrEditRoomBooking.Resources.cancelledDateAndTime}
            labelSubtitle={
              AddOrEditRoomBooking.Resources.youMustChooseBasedOnSpaceTimezone
            }
            isRequired={true}
            date={this.state.cancelledAt}
            dateName={nameof((s) => s.cancelledAt)}
            isDatePickerDisabled={this.props.isEditMode}
            isTimePickerDisabled={this.props.isEditMode}
            hideTimePicker={true}
            errors={this.getFieldErrors(
              nameof((s) => s.cancelledAtDateAndTime)
            )}
            handleOnChange={this.handleInputChangeWithAutomatedTime}
          />
        ) : null}

        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-secondary"
            onClick={() => this.goToRoomLogs()}
          >
            {General.Resources.cancelButtonLabel}
          </button>
          <button
            className="btn-big btn-primary"
            onClick={() => this.saveDetails()}
          >
            {General.Resources.save}
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        validator: AddOrEditRoomBookingFormValidator,
        ...state.addOrEditRoomBooking,
        ownProps,
      };
    },
    {
      saveDetails: AddOrEditRoomBookingStore.actionCreators.saveDetails,
    }
  )(AddOrEditRoomBookingForm as any)
);

interface IHourlyBookingProps {
  labelTitle: string;
  labelSubtitle: string;
  isRequired: boolean;
  date: string;
  dateName: string;
  startTime: string;
  endTime: string;
  startTimeName: string;
  endTimeName: string;
  isDatePickerDisabled: boolean;
  isTimePickerDisabled: boolean;
  errors: string[];
  handleDateOnChange: (value: string) => void;
  handleTimeOnChange: (name: string, value: string) => void;
}

interface IHourlyBookingState {
  date?: string;
  startTime?: string;
  endTime?: string;
}

class AntdDateTimePickerHourlyBookingFormWrapper extends React.PureComponent<
  IHourlyBookingProps,
  IHourlyBookingState
> {
  constructor(props) {
    super(props);

    this.state = {
      date: this.props.date,
      startTime: this.props.startTime,
      endTime: this.props.endTime,
    };
  }

  @bind
  handleDateOnChange(value) {
    this.setState({
      date: value,
    });

    this.props.handleDateOnChange(value);
  }

  @bind
  handleTimeOnChange(stateName, name, value) {
    this.setState({
      [stateName]: value,
    });

    this.props.handleTimeOnChange(name, value);
  }

  render() {
    return (
      <div className="form-line">
        <div className="line-info">
          <div className="title">
            {this.props.labelTitle}{" "}
            {this.props.isRequired ? (
              <span className="mandatory">*</span>
            ) : null}
          </div>
          <div className="description">{this.props.labelSubtitle}</div>
        </div>

        <div className="line-action">
          <DatePicker
            className={"form-item"}
            disabled={this.props.isDatePickerDisabled}
            format="DD-MM-YYYY"
            value={
              this.state.date ? dayjs(this.state.date, "DD-MM-YYYY") : null
            }
            onChange={(value, dateString) =>
              this.handleDateOnChange(dateString)
            }
          />
          <TimePicker
            className={"form-item"}
            disabled={this.props.isTimePickerDisabled}
            format="HH:mm"
            value={
              this.state.startTime ? dayjs(this.state.startTime, "HH:mm") : null
            }
            onChange={(value, timeString) =>
              this.handleTimeOnChange(
                nameof((s) => s.startTime),
                this.props.startTimeName,
                timeString
              )
            }
          />
          -
          <TimePicker
            className={"form-item"}
            disabled={this.props.isTimePickerDisabled}
            format="HH:mm"
            value={
              this.state.endTime ? dayjs(this.state.endTime, "HH:mm") : null
            }
            onChange={(value, timeString) =>
              this.handleTimeOnChange(
                nameof((s) => s.endTime),
                this.props.endTimeName,
                timeString
              )
            }
          />
          <div>
            {this.props.errors.map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
