import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { LoginStore } from "../../store/LoginStore";
import { withRouter } from "../../components/Shared/withRouter";
import { Button, Input } from "antd";
import bind from "bind-decorator";
import { ApplicationState } from "../../store";

class LoginPage extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }

  @bind
  login() {
    this.props.login(this.state.email, this.state.password);
  }

  render() {
    return (
      <div className="page login">
        <Helmet title={"Login"} />

        <div className="auth-box-container">
          {this.props.loginState == "LOGGING_IN" && <div>Logging in...</div>}

          {this.props.loginState == "LOGIN_FAILED" && (
            <div>{this.props.loginError}</div>
          )}

          <div className="input-section">
            <div className="logo-container">
              <img
                src="/assets/images/logo_app_dark.svg"
                className="logo-auth"
                alt="logo"
              ></img>
            </div>

            <div className="title">Super Admin</div>

            <div className="description">
              <span className="highlighted">Welcome!</span> Please complete the
              information below to sign in
            </div>

            <div className="input-fields">
              <Input
                placeholder="Email address"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <Input.Password
                placeholder="Password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </div>

            <div className="button">
              <Button
                className="login-ant-btn-default"
                onClick={() => this.login()}
              >
                Login
              </Button>
            </div>
          </div>

          <div className="illustration">
            <div className="photo-container">
              <img
                src="/assets/images/bg_login.png"
                className="auth-illustration"
              ></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect((state: ApplicationState) => {
    return {
      ...state.login,
    };
  }, LoginStore.actionCreators)(LoginPage)
);
