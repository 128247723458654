import React from "react";
import bind from "bind-decorator";
import { Select } from "antd";
import { connect } from "react-redux";

import AdminLogsTable from "../Tables/AdminLogsTable";
import { IAntdSelectListItem } from "../../../models/Common/IAntdSelectListItem";
import { String_Format, getPromiseFromAction } from "../../../utils/utils";
import { ApplicationState } from "../../../store";
import { AdminLogsStore } from "../../../store/AdminLogsStore";
import { LookupStore } from "../../../store/LookupStore";
import { DefaultFilterValues } from "../../../enums/Common/DefaultFilterValues";
import Loading from "../../Shared/Loading";

interface IProps {
  adminUsers: IAntdSelectListItem[];
  selectedUser: string;

  getAdminUsers: (withAllOption: boolean) => void;
  changeSelectedUser: (value: string) => any;

  reload: () => any;
  changeCurrentPage: (currentPage: number) => void;
  setFilters: (filters: string) => void;
}

class AdminLogs extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.props.getAdminUsers(true);
  }

  adminUserIdFilterTemplate = `adminUserId eq {0}`;

  @bind
  rebuildODataAndGetData() {
    var filters = [];

    if (
      this.props.selectedUser &&
      this.props.selectedUser != DefaultFilterValues.All.toString()
    ) {
      filters.push(
        `(${String_Format(
          this.adminUserIdFilterTemplate,
          this.props.selectedUser
        )})`
      );
    }

    var oDataFilter = filters.join(" and ");
    this.props.setFilters(oDataFilter);
    getPromiseFromAction(this.props.reload()).then(() =>
      this.props.changeCurrentPage(1)
    );
  }

  @bind
  onUserFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.changeSelectedUser(value)).then(() => {
      this.rebuildODataAndGetData();
    });
  }

  render() {
    if (!this.props.adminUsers) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div>
          <div className="filter-bar">
            <div className="filter-item">
              <div className="input-group">
                <Select
                  options={this.props.adminUsers}
                  onChange={this.onUserFilterChange}
                  value={this.props.selectedUser}
                  showSearch
                  optionFilterProp="label"
                />
              </div>
            </div>
          </div>

          <AdminLogsTable />
        </div>
      );
    }
  }
}

export default connect(
  (state: ApplicationState, ownProps: any) => {
    return {
      adminUsers: state.lookup.adminUsers,
      selectedUser: state.adminLogs.selectedUser,
    };
  },
  {
    getAdminUsers: LookupStore.actionCreators.getAdminUsers,
    changeSelectedUser: AdminLogsStore.actionCreators.setSelectedUser,
    reload: AdminLogsStore.actionCreators.reload,
    changeCurrentPage: AdminLogsStore.actionCreators.changeCurrentPage,
    setFilters: AdminLogsStore.actionCreators.setFilters,
  }
)(AdminLogs);
