import React from "react";
import { SpaceItem } from "../../models/Spaces/SpaceItem";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import PaginationBar from "../Shared/PaginationBar";
import { SpaceGridStore } from "../../store/SpaceGridStore";
import General from "../../resources/AdminUI/General";
import bind from "bind-decorator";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { NavigateFunction } from "react-router";
import { withRouter } from "../Shared/withRouter";
import { SpaceCategories } from "../../enums/Spaces/SpaceCategories";
import Loading from "../Shared/Loading";

interface IProps {
  spaces?: SpaceItem[];
  loading?: boolean;

  numberOfSpaces?: number;
  itemsPerPage?: number;
  firstIndexFromPage?: number;
  lastIndexFromPage?: number;

  navigation?: NavigateFunction;

  reload?: () => void;
  changeCurrentPage?: (currentPage: number) => void;
  initialize?: () => void;
}

interface IState {}

class SpacesTable extends React.PureComponent<any, IState> {
  constructor(props) {
    super(props);

    this.state = {};
  }
  public static defaultProps: Partial<IProps> = {
    loading: false,
  };
  tableClass: string = "table";
  hiddenColumns = [
    "id",
    "createdAt",
    "cityName",
    "cityId",
    "countryName",
    "countryId",
    "categoryName",
    "categoryId",
    "phone",
    "maxSeats",
    "numberOfPrivateOffices",
    "requireBooking",
    "statusId",
    "statusName",
    "lastModifiedAt",
    "mealIncluded",
  ];

  itemsPerPage: number = 15;
  maxPagesDisplayed: number = 3;
  pageNeighbours: number = 1;
  hasRefreshButton: boolean = false;

  @bind
  goToEditSpace(spaceId) {
    this.props.navigate(ApplicationPathBuilder.EditSpace(spaceId));
  }

  @bind
  goToEditOffice(spaceId) {
    this.props.navigate(ApplicationPathBuilder.EditOffice(spaceId));
  }

  @bind
  goToSpaceDetails(spaceId) {
    this.props.navigate(ApplicationPathBuilder.ViewSpaceDetails(spaceId));
  }

  componentDidMount(): void {
    if (
      !this.props.spaces ||
      (this.props.spaces.length == 0 && !this.props.filters)
    ) {
      this.props.initialize(this.itemsPerPage, this.props.filters);
    } else {
      this.props.reload();
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    if (!this.props.spaces || this.props.spaces.length == 0) {
      return <div>{General.Resources.noElements}</div>;
    } else {
      return (
        <div>
          <div className="table-wrapper workspaces-table">
            <table className={this.tableClass}>
              <thead>
                <tr>
                  <th className="row-actions">
                    <span>{General.Resources.actions}</span>
                  </th>
                  {this.props.spaces[0].TrueProperties.filter(
                    (p) => this.hiddenColumns.indexOf(p) < 0
                  ).map((p) => (
                    <th data-property={p} key={p}>
                      {this.props.spaces[0].GetDisplayNameForProperty(p)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.props.spaces.map((us) => {
                  return (
                    <tr key={us.id}>
                      <td className="row-actions">
                        {us.categoryId ==
                        SpaceCategories.CompanyOffice.toString() ? (
                          <button
                            className="btn-table-action"
                            onClick={() => this.goToEditOffice(us.id)}
                          >
                            <span className="material-symbols-outlined">
                              edit
                            </span>
                          </button>
                        ) : (
                          <button
                            className="btn-table-action"
                            onClick={() => this.goToEditSpace(us.id)}
                          >
                            <span className="material-symbols-outlined">
                              edit
                            </span>
                          </button>
                        )}
                        <button
                          className="btn-table-action"
                          onClick={() => this.goToSpaceDetails(us.id)}
                        >
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        </button>
                      </td>
                      {us.TrueProperties.filter(
                        (p) => this.hiddenColumns.indexOf(p) < 0
                      ).map((p) => (
                        <td
                          data-property={p}
                          data-label={
                            this.props.hasHeader
                              ? this.props.spaces[0].GetColumnNameForProperty(p)
                              : null
                          }
                          key={p}
                        >
                          {us.GetDisplayValueForProperty(p)}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <PaginationBar
            totalItems={this.props.numberOfSpaces}
            reload={this.props.reload}
            changeCurrentPage={this.props.changeCurrentPage}
            firstIndexFromPage={this.props.firstIndexFromPage}
            lastIndexFromPage={this.props.lastIndexFromPage}
            pageIndexArray={this.props.pageIndexArray}
            maxPagesDisplayed={this.maxPagesDisplayed}
            pageNeighbours={this.pageNeighbours}
          />
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return state.spaceGrid;
    },
    {
      initialize: SpaceGridStore.actionCreators.initialize,
      changeCurrentPage: SpaceGridStore.actionCreators.changeCurrentPage,
      reload: SpaceGridStore.actionCreators.reload,
    }
  )(SpacesTable as any)
);
