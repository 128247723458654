import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { bindActionCreators } from "redux";
import update from "immutability-helper";

import { ApplicationState } from "../../store";
import PaginationBar from "../Shared/PaginationBar";
import General from "../../resources/AdminUI/General";
import { HotdeskBookingsItem } from "../../models/HotdeskBookings/HotdeskBookingsItem";
import { HotdeskBookingsGridStore } from "../../store/HotdeskBookingsGridStore";
import { getPromiseFromAction } from "../../utils/utils";
import IPageIndex from "../../models/Pagination/IPageIndex";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import HotdeskBookingsTableBody from "./HotdeskBookingsTableBody";
import HotdeskBookingsTableHead from "./HotdeskBookingsTableHead";
import Loading from "../Shared/Loading";

interface IProps {
  hotdeskBookings?: HotdeskBookingsItem[];
  loading?: boolean;
  hiddenColumns?: string[];
  expandableColumns?: string[];
  isHotdeskBookingHiddenList: boolean[];
  filters?: string;
  numberOfHotdeskBookings?: number;
  firstIndexFromPage?: number;
  lastIndexFromPage?: number;
  showActionsColumn?: boolean;
  pageIndexArray?: IPageIndex[];
  reload?: () => any;
  changeCurrentPage?: (currentPage: number) => void;
  initialize?: (
    defaultSelectedItemsPerPageOption: number,
    filters: string
  ) => any;
}

const HotdeskBookingsTable: React.FC<IProps> = ({
  hotdeskBookings = [],
  loading = false,
  isHotdeskBookingHiddenList: hiddenListFromProps = [],
  ...props
}) => {
  const [isHotdeskBookingHiddenList, setIsHotdeskBookingHiddenList] = useState<
    boolean[]
  >([]);
  const itemsPerPage = 15;
  const maxPagesDisplayed = 3;
  const pageNeighbours = 1;

  const navigate = useNavigate();

  const toggleIsExpanded = (index: number) => {
    setIsHotdeskBookingHiddenList(
      update(isHotdeskBookingHiddenList, {
        [index]: { $set: !isHotdeskBookingHiddenList[index] },
      })
    );
  };

  const changeCurrentPage = (currentPage: number) => {
    props.changeCurrentPage?.(currentPage);
    setIsHotdeskBookingHiddenList(
      new Array(hotdeskBookings?.length || 0).fill(true)
    );
  };

  const goToEdit = (id: number) => {
    navigate(ApplicationPathBuilder.EditHotdeskBooking(id));
  };

  // Initialize the table when `hotdeskBookings` changes
  useEffect(() => {
    if (hotdeskBookings.length > 0) {
      setIsHotdeskBookingHiddenList(
        new Array(hotdeskBookings.length).fill(true)
      );
    }
  }, [hotdeskBookings]);

  useEffect(() => {
    if (!hotdeskBookings.length && !props.filters) {
      getPromiseFromAction(props.initialize?.(itemsPerPage, props.filters));
    } else if (hotdeskBookings.length) {
      getPromiseFromAction(props.reload?.());
    }
  }, [hotdeskBookings.length, props.filters]);

  if (loading) {
    return <Loading />;
  }

  if (!hotdeskBookings.length) {
    return <div>{General.Resources.noElements}</div>;
  }

  return (
    <div>
      <div className="table-wrapper desks-logs-table">
        <table className="table">
          <HotdeskBookingsTableHead
            showActionsColumn={props.showActionsColumn}
            hotdeskBookings={hotdeskBookings}
            hiddenColumns={props.hiddenColumns}
          />
          <HotdeskBookingsTableBody
            hotdeskBookings={hotdeskBookings}
            hiddenColumns={props.hiddenColumns}
            isHotdeskBookingHiddenList={isHotdeskBookingHiddenList}
            showActionsColumn={props.showActionsColumn}
            expandableColumns={props.expandableColumns}
            toggleIsExpanded={toggleIsExpanded}
            goToEdit={goToEdit}
          />
        </table>
      </div>
      <PaginationBar
        totalItems={props.numberOfHotdeskBookings}
        reload={props.reload}
        changeCurrentPage={changeCurrentPage}
        firstIndexFromPage={props.firstIndexFromPage}
        lastIndexFromPage={props.lastIndexFromPage}
        pageIndexArray={props.pageIndexArray}
        maxPagesDisplayed={maxPagesDisplayed}
        pageNeighbours={pageNeighbours}
      />
    </div>
  );
};

export default connect(
  (state: ApplicationState, ownProps: any) =>
    state.hotdeskBookingsGrid[ownProps.componentId] || {},
  (dispatch, ownProps: any) =>
    bindActionCreators(
      {
        ...HotdeskBookingsGridStore.getActionCreators(ownProps.componentId),
      },
      dispatch
    )
)(HotdeskBookingsTable);
