// useBenefitsTable.ts
import { useState } from "react";

import { BenefitBeneficiaries } from "../../../enums/Benefits/BenefitBeneficiaries";
import { BenefitStartDateOptions } from "../../../enums/Benefits/BenefitStartDateOptions";
import { BenefitItem } from "../../../models/Benefits/BenefitItem";
import BenefitBeneficiaryList from "../../../resources/AdminUI/Benefits/BenefitBeneficiaryList";
import StartDateOptions from "../../../resources/AdminUI/Benefits/StartDateOptions";
import { firstLetterToLowerCase } from "../../../utils/utils";

export const useBenefitsTable = () => {
  const [state, setState] = useState({
    isAssignBenefitModalOpen: false,
    isDeactivateBenefitModalOpen: false,
    isShowBenefitsHistoryModalOpen: false,
    selectedBenefit: null as BenefitItem | null,
  });

  const showAssignBenefitModal = (benefit: BenefitItem) => {
    setState({
      ...state,
      isAssignBenefitModalOpen: true,
      selectedBenefit: benefit,
    });
  };

  const hideAssignBenefitModal = () => {
    setState({ ...state, isAssignBenefitModalOpen: false });
  };

  const showDeactivateBenefitModal = (benefit: BenefitItem) => {
    setState({
      ...state,
      isDeactivateBenefitModalOpen: true,
      selectedBenefit: benefit,
    });
  };

  const hideDeactivateBenefitModal = () => {
    setState({ ...state, isDeactivateBenefitModalOpen: false });
  };

  const showBenefitsHistoryModal = (benefit: BenefitItem) => {
    setState({
      ...state,
      isShowBenefitsHistoryModalOpen: true,
      selectedBenefit: benefit,
    });
  };

  const hideBenefitsHistoryModal = () => {
    setState({ ...state, isShowBenefitsHistoryModalOpen: false });
  };

  const getAllBenefitStartDateOptions = () => {
    return Object.keys(BenefitStartDateOptions)
      .filter((k) => typeof BenefitStartDateOptions[k as any] === "number")
      .map((key) => ({
        value: BenefitStartDateOptions[key as any].toString(),
        text: StartDateOptions.Resources[firstLetterToLowerCase(key)],
      }));
  };

  const getAllBenefitBeneficiaryList = () => {
    return Object.keys(BenefitBeneficiaries)
      .filter((k) => typeof BenefitBeneficiaries[k as any] === "number")
      .map((key) => ({
        value: BenefitBeneficiaries[key as any].toString(),
        text: BenefitBeneficiaryList.Resources[firstLetterToLowerCase(key)],
      }));
  };

  return {
    state,
    showAssignBenefitModal,
    hideAssignBenefitModal,
    showDeactivateBenefitModal,
    hideDeactivateBenefitModal,
    showBenefitsHistoryModal,
    hideBenefitsHistoryModal,
    getAllBenefitStartDateOptions,
    getAllBenefitBeneficiaryList,
  };
};
