import React, { FC, useState } from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";

interface DateTimePickerProps {
  labelTitle: string;
  labelSubtitle: string;
  isRequired: boolean;
  date?: string;
  dateName?: string;
  time?: string;
  timeName?: string;
  isDatePickerDisabled?: boolean;
  isTimePickerDisabled?: boolean;
  hideTimePicker?: boolean;
  errors: string[];
  handleOnChange: (name: string, value: string) => void;
}

const AntdDateTimePickerFormWrapper: FC<DateTimePickerProps> = ({
  labelTitle,
  labelSubtitle,
  isRequired,
  date: initialDate,
  dateName,
  time: initialTime,
  timeName,
  isDatePickerDisabled,
  isTimePickerDisabled,
  hideTimePicker,
  errors,
  handleOnChange,
}) => {
  const [date, setDate] = useState<string | undefined>(initialDate);
  const [time, setTime] = useState<string | undefined>(initialTime);

  const handleDateTimeChange = (
    stateName: string,
    name: string | undefined,
    value: string
  ) => {
    if (stateName === "date") {
      setDate(value);
    } else if (stateName === "time") {
      setTime(value);
    }

    if (name) {
      handleOnChange(name, value);
    }
  };

  return (
    <div className="form-line">
      <div className="line-info">
        <div className="title">
          {labelTitle} {isRequired && <span className="mandatory">*</span>}
        </div>
        <div className="description">{labelSubtitle}</div>
      </div>

      <div className="line-action">
        <div className="input-group">
          <DatePicker
            className="form-item"
            disabled={isDatePickerDisabled}
            format="DD-MM-YYYY"
            value={date ? dayjs(date, "DD-MM-YYYY") : null}
            onChange={(_, dateString) =>
              handleDateTimeChange("date", dateName, dateString)
            }
          />
        </div>
        {!hideTimePicker && (
          <TimePicker
            className="form-item"
            disabled={isTimePickerDisabled}
            format="HH:mm"
            value={time ? dayjs(time, "HH:mm") : null}
            onChange={(_, timeString) =>
              handleDateTimeChange("time", timeName, timeString)
            }
          />
        )}

        <div>
          {errors.map((err) => (
            <span key={err} className="field-validation-error">
              {err}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AntdDateTimePickerFormWrapper;
