import React from "react";
import AccountsAndLogs from "../../../resources/AdminUI/AccountsAndLogs/AccountsAndLogs";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import { getPromiseFromAction, nameof } from "../../../utils/utils";
import { ValidatedComponent } from "../../FormValidations/Base/ValidatedComponent";
import { bind } from "bind-decorator";
import General from "../../../resources/AdminUI/General";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { AddAminCredentialsValidator } from "../../FormValidations/Validators/AddAdminCredentialsValidator";
import { AdminAccountsStore } from "../../../store/AdminAccountsStore";
import Validations from "../../../resources/Common/Validations";
import FormInput from "../../Shared/Fields/FormInput";

interface IProps {
  title: string;
  description: string;
  isChangePassword?: boolean;
  id?: number;
  name?: string;
  email?: string;

  hasServerSideErrors?: boolean;
  errors?: string;

  onCancelCallback: () => void;
  onSaveCredentials?: (
    name: string,
    email: string,
    password: string,
    isChangePassword?: boolean,
    id?: number
  ) => any;
  getAdminAccounts?: () => void;
}

interface IState {
  name: string;
  email: string;
  password: string;
}

class AddOrEditAdminCredentialsForms extends ValidatedComponent<
  IProps,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.name,
      email: this.props.email,
      password: null,
    };
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      ...this.state,
      [name]: data,
    });
  }

  @bind
  handleOnSaveCredentials() {
    this.validate(() => {
      getPromiseFromAction(
        this.props.onSaveCredentials(
          this.state.name,
          this.state.email,
          this.state.password,
          this.props.isChangePassword,
          this.props.id
        )
      ).then(() => {
        if (!this.props.hasServerSideErrors) {
          this.props.onCancelCallback();
          this.props.getAdminAccounts();
        }
      });
    });
  }

  render() {
    return (
      <div className="modal-container">
        <div className="modal-title">{this.props.title}</div>

        <div className="modal-description">{this.props.description}</div>

        {(!this.props.id || !this.props.isChangePassword) && (
          <div>
            <div className="form-line">
              <div className="line-info">
                <div className="title in-modal">
                  {AccountsAndLogs.Resources.nameLabel}
                  <span className="mandatory">*</span>
                </div>
              </div>
              <div className="line-action">
                <FormInput
                  placeholder={AccountsAndLogs.Resources.nameLabel}
                  inputType={EntityFieldInputType.Text}
                  className={"form-control"}
                  name={nameof((s) => s.name)}
                  onChange={this.handleInputChange}
                  errors={this.getFieldErrors(nameof((s) => s.name))}
                  value={this.state.name}
                />
              </div>
            </div>
            <div className="form-line">
              <div className="line-info">
                <div className="title in-modal">
                  {AccountsAndLogs.Resources.emailAddressLabel}
                  <span className="mandatory">*</span>
                </div>
              </div>
              <div className="line-action">
                <FormInput
                  placeholder={AccountsAndLogs.Resources.emailAddressLabel}
                  inputType={EntityFieldInputType.Text}
                  className={"form-control"}
                  name={nameof((s) => s.email)}
                  onChange={this.handleInputChange}
                  errors={this.getFieldErrors(nameof((s) => s.email))}
                  value={this.state.email}
                />
              </div>
            </div>
          </div>
        )}

        {(!this.props.id || this.props.isChangePassword) && (
          <div className="form-line">
            <div className="line-info">
              <div className="title in-modal">
                {AccountsAndLogs.Resources.passwordLabel}
                <span className="mandatory">*</span>
              </div>
            </div>
            <div className="line-action">
              <FormInput
                placeholder={AccountsAndLogs.Resources.passwordLabel}
                inputType={EntityFieldInputType.Password}
                className={"form-control"}
                name={nameof((s) => s.password)}
                onChange={this.handleInputChange}
                errors={this.getFieldErrors(nameof((s) => s.password))}
                value={this.state.password}
              />
            </div>
          </div>
        )}

        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-secondary"
            onClick={this.props.onCancelCallback}
          >
            {General.Resources.cancelButtonLabel}
          </button>
          <button
            className="btn-big btn-primary"
            onClick={this.handleOnSaveCredentials}
          >
            {General.Resources.save}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: IProps) => {
    return {
      ...ownProps,
      validator: AddAminCredentialsValidator,
    };
  },
  {
    onSaveCredentials: AdminAccountsStore.actionCreators.saveAdminAccount,
    getAdminAccounts: AdminAccountsStore.actionCreators.getAdminAccounts,
  }
)(AddOrEditAdminCredentialsForms as any);
