import React, { useState } from "react";

interface FileInputProps {
  label?: string;
  className?: string;
  errors?: string[];
  value?: any;
  readOnly?: boolean;
  hidden?: boolean;
  onChange?: (data: any) => void;
  placeholder?: string;
  name?: string;
}

const FileInput: React.FC<FileInputProps> = ({
  label,
  className,
  errors = [],
  value,
  readOnly,
  hidden,
  onChange,
  placeholder,
  name,
}) => {
  const [localValue, setLocalValue] = useState(value);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const uploadedFile = { formFile: file, fileUrl: url };
      setLocalValue(uploadedFile);
      onChange?.(uploadedFile);
    }
  };

  return (
    <div className="form-item">
      {label && <label>{label}</label>}
      {localValue?.fileUrl && (
        <a target="_blank" rel="noopener noreferrer" href={localValue.fileUrl}>
          Download {localValue.formFile?.name || label}
        </a>
      )}
      <div className="form-upload">
        <input
          type="file"
          placeholder={placeholder}
          className={errors.length > 0 ? "input-validation-error" : ""}
          onChange={readOnly ? undefined : handleFileChange}
          name={name}
          readOnly={readOnly}
          hidden={hidden}
        />
        <div className="form-upload-wrapper">
          <label>{localValue?.formFile?.name || "No File Chosen"}</label>
        </div>
      </div>
      {errors.map((err) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default FileInput;
