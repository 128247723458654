// TagButton.tsx
import { Input, Tag, Tooltip } from "antd";
import React from "react";
import { PlusOutlined, PlusCircleOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useTagButton } from "./useTagButton";

interface IProps {
  tags: string[];
  buttonText: string;
  onListChange?: (list: string[]) => void;
  type?: "email" | "phone" | "text";
}

const TagButton: React.FC<IProps> = ({
  tags: initialTags,
  buttonText,
  onListChange,
  type = "text",
}) => {
  const {
    tags,
    inputValue,
    isInputVisible,
    inputError,
    inputRef,
    phoneInputRef,
    handleKeyPress,
    handleValidation,
    handleClose,
    showInput,
    handleInputChange,
    handlePhoneInputChange,
    handleBlur,
  } = useTagButton({ initialTags, onListChange, type });

  const contentByType = () => {
    if (type === "phone") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative", width: "100%" }}>
            <PhoneInput
              ref={phoneInputRef}
              placeholder="Enter phone number"
              value={inputValue}
              onChange={handlePhoneInputChange}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
              international={true}
              countryCallingCodeEditable={false}
            />
            {inputError ? (
              <span className="field-validation-error tag-btn-message">
                {inputError}
              </span>
            ) : (
              <span className="tag-btn-warning-message tag-btn-message">
                Press <PlusCircleOutlined /> to add the phone number
              </span>
            )}
          </div>
          <Tooltip title="Add phone number">
            <PlusCircleOutlined
              onClick={() => handleValidation(inputValue)}
              style={{ marginLeft: 8 }}
            />
          </Tooltip>
        </div>
      );
    }

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ position: "relative", width: "100%" }}>
          <Input
            ref={inputRef}
            type={type ?? "text"}
            size="middle"
            style={{ width: 200 }}
            placeholder={type === "email" ? "Enter email" : "Enter text"}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onPressEnter={handleKeyPress}
          />
          {inputError ? (
            <span className="field-validation-error tag-btn-message tag-btn-message-email">
              {inputError}
            </span>
          ) : (
            <span className="tag-btn-warning-message tag-btn-message tag-btn-message-email">
              Press <PlusCircleOutlined /> to add{" "}
              {type === "email" ? "email" : "value"}
            </span>
          )}
        </div>
        <Tooltip title={`Add ${type === "email" ? "email" : "value"}`}>
          <PlusCircleOutlined
            onClick={() => handleValidation(inputValue)}
            style={{ marginLeft: 8 }}
          />
        </Tooltip>
      </div>
    );
  };

  return (
    <div>
      {isInputVisible ? (
        contentByType()
      ) : (
        <Tag
          onClick={showInput}
          style={{ borderStyle: "dashed", padding: "10px", cursor: "pointer" }}
        >
          <PlusOutlined />
          {buttonText}
        </Tag>
      )}

      <div style={{ marginTop: 32 }}>
        {tags.map((tag) => (
          <Tag
            key={tag}
            closable
            onClose={(e) => {
              e.preventDefault();
              handleClose(tag);
            }}
            style={{ marginBottom: 8 }}
          >
            {tag}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default TagButton;
