import { Action, Reducer } from "redux";
import { AppThunkAction, StateDictionary } from ".";
import update from "immutability-helper";
import IPageIndex from "../models/Pagination/IPageIndex";
import HotdeskBookingsService from "../services/HotdeskBookingsService";
import { IHotdeskBookingsItem } from "../models/HotdeskBookings/Interfaces/IHotdeskBookingsItem";
import { DefaultFilterValues } from "../enums/Common/DefaultFilterValues";

export module HotdeskBookingsGridStore {
  export interface IState {
    itemsPerPage: number;
    currentPage: number;
    firstIndexFromPage: number;
    lastIndexFromPage: number;
    pageIndexArray: IPageIndex[];
    filters?: string;
    loading?: boolean;

    numberOfHotdeskBookings?: number;
    hotdeskBookings?: IHotdeskBookingsItem[];
    isHotdeskBookingHiddenList?: boolean[];
    searchTerm?: string;
    singleSelectedStatus?: string;
    singleSelectedCountry?: string;
    singleSelectedCity?: string;
    startDateSearchTemplate?: number;
    endDateSearchTemplate?: number;
  }

  const initialState: IState = {
    itemsPerPage: 10,
    currentPage: 1,
    firstIndexFromPage: 1,
    lastIndexFromPage: 1,
    pageIndexArray: [],
  };

  export enum Actions {
    ToggleLoading = "HOTDESK_BOOKINGS_GRID_TOGGLE_LOADING",
    Initialize = "HOTDESK_BOOKINGS_GRID_INITIALIZE",
    InitializeHiddenList = "HOTDESK_BOOKINGS_GRID_INITIALIZE_HIDDEN_LIST",
    ReloadData = "HOTDESK_BOOKINGS_GRID_RELOAD_DATA",
    RecalculatePageArray = "HOTDESK_BOOKINGS_GRID_RECALCULATE_PAGE_ARRAY",
    RecalculateIndexes = "HOTDESK_BOOKINGS_GRID_RECALCULATE_INDEXES",
    ChangeCurrentPage = "HOTDESK_BOOKINGS_GRID_CHANGE_CURRENT_PAGE",
    SetFilters = "HOTDESK_BOOKINGS_GRID_SET_FILTERS",

    ChangeSearchTerm = "HOTDESK_BOOKINGS_GRID_CHANGE_SEARCH_TERM",
    ChangeSingleSelectedStatus = "HOTDESK_BOOKINGS_GRID_CHANGE_SINGLE_SELECTED_STATUS",
    ChangeSingleSelectedCountry = "HOTDESK_BOOKINGS_GRID_CHANGE_SINGLE_SELECTED_COUNTRY",
    ChangeSingleSelectedCity = "HOTDESK_BOOKINGS_GRID_CHANGE_SINGLE_SELECTED_CITY",
    ChangeStartDateSearchTemplateAndEndDateSearchTemplate = "HOTDESK_BOOKINGS_GRID_CHANGE_START_DATE_SEARCH_TEMPLATE_AND_END_DATE_SEARCH_TEMPLATE",
  }

  interface IInitialize {
    componentId: string;
    type: Actions.Initialize;
    itemsPerPage: number;
  }

  interface IInitializeHiddenList {
    componentId: string;
    type: Actions.InitializeHiddenList;
  }

  interface IToggleLoading {
    componentId: string;
    type: Actions.ToggleLoading;
    loadingValue: boolean;
  }

  interface IReloadData {
    componentId: string;
    type: Actions.ReloadData;
    hotdeskBookings: IHotdeskBookingsItem[];
    numberOfHotdeskBookings: number;
  }

  interface IRecalculatePageArray {
    componentId: string;
    type: Actions.RecalculatePageArray;
  }

  interface IRecalculateIndexes {
    componentId: string;
    type: Actions.RecalculateIndexes;
  }

  interface IChangeCurrentPage {
    componentId: string;
    type: Actions.ChangeCurrentPage;
    currentPage: number;
  }

  interface ISetFilters {
    componentId: string;
    type: Actions.SetFilters;
    filters: string;
  }

  interface IChangeSearchTerm {
    componentId: string;
    type: Actions.ChangeSearchTerm;
    filterValue: string;
  }

  interface IChangeSingleSelectedStatus {
    componentId: string;
    type: Actions.ChangeSingleSelectedStatus;
    filterValue: string;
  }

  interface IChangeSingleSelectedCountry {
    componentId: string;
    type: Actions.ChangeSingleSelectedCountry;
    filterValue: string;
  }

  interface IChangeSingleSelectedCity {
    componentId: string;
    type: Actions.ChangeSingleSelectedCity;
    filterValue: string;
  }

  interface IChangeStartDateSearchTemplateAndEndDateSearchTemplate {
    componentId: string;
    type: Actions.ChangeStartDateSearchTemplateAndEndDateSearchTemplate;
    startFilterValue: number;
    endFilterValue: number;
  }

  type KnownAction =
    | IToggleLoading
    | IInitialize
    | IInitializeHiddenList
    | IReloadData
    | IRecalculatePageArray
    | IRecalculateIndexes
    | IChangeCurrentPage
    | ISetFilters
    | IChangeSearchTerm
    | IChangeSingleSelectedStatus
    | IChangeSingleSelectedCountry
    | IChangeSingleSelectedCity
    | IChangeStartDateSearchTemplateAndEndDateSearchTemplate;

  export const getActionCreators = (componentId: string) => ({
    initialize:
      (
        defaultSelectedItemsPerPageOption: number,
        filters: string
      ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        const state = getState().hotdeskBookingsGrid[componentId];

        const searchTerm = state && state.searchTerm ? state.searchTerm : "";
        const singleSelectedStatus =
          state && state.singleSelectedStatus
            ? state.singleSelectedStatus
            : DefaultFilterValues.All.toString();
        const singleSelectedCountry =
          state && state.singleSelectedCountry
            ? state.singleSelectedCountry
            : DefaultFilterValues.All.toString();
        const singleSelectedCity =
          state && state.singleSelectedCity
            ? state.singleSelectedCity
            : DefaultFilterValues.All.toString();
        const startDateSearchTemplate =
          state && state.startDateSearchTemplate
            ? state.startDateSearchTemplate
            : null;
        const endDateSearchTemplate =
          state && state.endDateSearchTemplate
            ? state.endDateSearchTemplate
            : null;

        dispatch({
          componentId,
          type: Actions.Initialize,
          itemsPerPage: defaultSelectedItemsPerPageOption,
        });
        dispatch({
          componentId,
          type: Actions.ToggleLoading,
          loadingValue: true,
        });

        const hotdeskBookings = await HotdeskBookingsService.getHotdeskBookings(
          defaultSelectedItemsPerPageOption,
          0,
          filters
        ).then((res) => res.value);
        const hotdeskBookingsMapped =
          HotdeskBookingsService.additionalMapping(hotdeskBookings);

        if (hotdeskBookingsMapped.entities.length != 0) {
          dispatch({
            componentId,
            type: Actions.ReloadData,
            hotdeskBookings: hotdeskBookingsMapped.entities,
            numberOfHotdeskBookings: hotdeskBookingsMapped.numberOfEntities,
          });
        } else {
          dispatch({
            componentId,
            type: Actions.ReloadData,
            hotdeskBookings: [],
            numberOfHotdeskBookings: 0,
          });
        }

        dispatch({ componentId, type: Actions.SetFilters, filters: filters });
        dispatch({
          componentId,
          type: Actions.ChangeSearchTerm,
          filterValue: searchTerm,
        });
        dispatch({
          componentId,
          type: Actions.ChangeSingleSelectedStatus,
          filterValue: singleSelectedStatus,
        });
        dispatch({
          componentId,
          type: Actions.ChangeSingleSelectedCountry,
          filterValue: singleSelectedCountry,
        });
        dispatch({
          componentId,
          type: Actions.ChangeSingleSelectedCity,
          filterValue: singleSelectedCity,
        });
        dispatch({
          componentId,
          type: Actions.ChangeStartDateSearchTemplateAndEndDateSearchTemplate,
          startFilterValue: startDateSearchTemplate,
          endFilterValue: endDateSearchTemplate,
        });
        dispatch({ componentId, type: Actions.InitializeHiddenList });
        dispatch({
          componentId,
          type: Actions.ChangeCurrentPage,
          currentPage: 1,
        });
        dispatch({ componentId, type: Actions.RecalculatePageArray });
        dispatch({ componentId, type: Actions.RecalculateIndexes });
        dispatch({
          componentId,
          type: Actions.ToggleLoading,
          loadingValue: false,
        });
      },
    reload: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState().hotdeskBookingsGrid[componentId];

      dispatch({
        componentId,
        type: Actions.ToggleLoading,
        loadingValue: true,
      });
      const take = state.itemsPerPage;
      const skip = (state.currentPage - 1) * state.itemsPerPage;
      const filters = state.filters;

      const hotdeskBookings = await HotdeskBookingsService.getHotdeskBookings(
        take,
        skip,
        filters
      ).then((res) => res.value);
      const hotdeskBookingsMapped =
        HotdeskBookingsService.additionalMapping(hotdeskBookings);

      dispatch({
        componentId,
        type: Actions.ReloadData,
        hotdeskBookings: hotdeskBookingsMapped.entities,
        numberOfHotdeskBookings: hotdeskBookingsMapped.numberOfEntities,
      });
      dispatch({ componentId, type: Actions.InitializeHiddenList });
      dispatch({
        componentId,
        type: Actions.ToggleLoading,
        loadingValue: false,
      });
    },
    changeCurrentPage:
      (currentPage: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ componentId, type: Actions.ChangeCurrentPage, currentPage });
        dispatch({ componentId, type: Actions.RecalculatePageArray });
        dispatch({ componentId, type: Actions.RecalculateIndexes });
      },
    setFilters:
      (filters: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ componentId, type: Actions.SetFilters, filters: filters });
      },
    setSearchTerm:
      (searchTerm: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          componentId,
          type: Actions.ChangeSearchTerm,
          filterValue: searchTerm,
        });
      },
    setSingleSelectedStatus:
      (singleSelectedStatus: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          componentId,
          type: Actions.ChangeSingleSelectedStatus,
          filterValue: singleSelectedStatus,
        });
      },
    setSingleSelectedCountry:
      (singleSelectedCountry: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          componentId,
          type: Actions.ChangeSingleSelectedCountry,
          filterValue: singleSelectedCountry,
        });
        dispatch({
          componentId,
          type: Actions.ChangeSingleSelectedCity,
          filterValue: DefaultFilterValues.All.toString(),
        });
      },
    setSingleSelectedCity:
      (singleSelectedCity: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          componentId,
          type: Actions.ChangeSingleSelectedCity,
          filterValue: singleSelectedCity,
        });
      },
    setStartDateSearchTemplateAndEndDateSearchTemplate:
      (
        startDateSearchTemplate: number,
        endDateSearchTemplate: number
      ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          componentId,
          type: Actions.ChangeStartDateSearchTemplateAndEndDateSearchTemplate,
          startFilterValue: startDateSearchTemplate,
          endFilterValue: endDateSearchTemplate,
        });
      },
  });

  export const reducer: Reducer<StateDictionary<IState>> = (
    currentState: StateDictionary<IState>,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    const componentId = action.componentId;
    const componentState = currentState?.[componentId];

    switch (action.type) {
      case Actions.Initialize:
        return {
          ...currentState,
          [componentId]: {
            ...initialState,
            itemsPerPage: action.itemsPerPage,
          },
        };
      case Actions.InitializeHiddenList:
        const hiddenList = new Array(componentState.itemsPerPage)
          .fill(undefined)
          .map((e) => true);
        return update(currentState, {
          [componentId]: {
            isHotdeskBookingHiddenList: { $set: hiddenList },
          },
        });
      case Actions.ToggleLoading:
        return update(currentState, {
          [componentId]: {
            loading: { $set: action.loadingValue },
          },
        });
      case Actions.ReloadData:
        return update(currentState, {
          [componentId]: {
            hotdeskBookings: { $set: action.hotdeskBookings },
            numberOfHotdeskBookings: { $set: action.numberOfHotdeskBookings },
          },
        });
      case Actions.ChangeCurrentPage:
        return update(currentState, {
          [componentId]: {
            currentPage: { $set: action.currentPage },
          },
        });
      case Actions.RecalculatePageArray:
        const totalPages =
          Math.floor(
            (componentState.numberOfHotdeskBookings - 1) /
              componentState.itemsPerPage
          ) + 1;
        const pageArray = [];
        pageArray.push({
          pageNumber: 1,
          isActive: componentState.currentPage === 1,
        } as IPageIndex);
        for (let i = 2; i <= totalPages; i++) {
          pageArray.push({
            pageNumber: i,
            isActive: componentState.currentPage === i,
          });
        }
        return update(currentState, {
          [componentId]: {
            pageIndexArray: { $set: pageArray },
          },
        });
      case Actions.RecalculateIndexes: {
        const firstIndex =
          (componentState.currentPage - 1) * componentState.itemsPerPage + 1;
        const totalPages = componentState.pageIndexArray.length;
        let lastIndex: number;

        if (totalPages == componentState.currentPage)
          lastIndex = Math.min(
            componentState.numberOfHotdeskBookings,
            componentState.itemsPerPage * totalPages
          );
        else
          lastIndex = componentState.currentPage * componentState.itemsPerPage;
        return update(currentState, {
          [componentId]: {
            firstIndexFromPage: { $set: firstIndex },
            lastIndexFromPage: { $set: lastIndex },
          },
        });
      }
      case Actions.SetFilters:
        return update(currentState, {
          [componentId]: {
            filters: { $set: action.filters },
          },
        });
      case Actions.ChangeSearchTerm:
        return update(currentState, {
          [componentId]: {
            searchTerm: { $set: action.filterValue },
          },
        });
      case Actions.ChangeSingleSelectedStatus:
        return update(currentState, {
          [componentId]: {
            singleSelectedStatus: { $set: action.filterValue },
          },
        });
      case Actions.ChangeSingleSelectedCountry:
        return update(currentState, {
          [componentId]: {
            singleSelectedCountry: { $set: action.filterValue },
          },
        });
      case Actions.ChangeSingleSelectedCity:
        return update(currentState, {
          [componentId]: {
            singleSelectedCity: { $set: action.filterValue },
          },
        });
      case Actions.ChangeStartDateSearchTemplateAndEndDateSearchTemplate:
        return update(currentState, {
          [componentId]: {
            startDateSearchTemplate: { $set: action.startFilterValue },
            endDateSearchTemplate: { $set: action.endFilterValue },
          },
        });
      default:
        return currentState || {};
    }
  };
}
