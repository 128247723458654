import React from "react";
import General from "../../resources/AdminUI/General";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { ISelectListItem } from "../../models/Common/ISelectListItem";
import { nameof } from "../../utils/utils";
import bind from "bind-decorator";
import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { DeclineRoomBookingConfirmationValidator } from "../FormValidations/Validators/DeclineRoomBookingConfirmationValidator";
import { Select } from "antd";

interface IProps {
  reasons: ISelectListItem[];
  id: number;
  onCancelCallback: () => void;
  onSaveCallback: (id: number, reasonId: string) => void;
}

interface IState {
  reasonId?: string;
}

class DeclineRoomBookingConfirmationModal extends ValidatedComponent<
  IProps,
  IState
> {
  constructor(props) {
    super(props);
  }

  @bind
  onSave() {
    this.validate(() =>
      this.props.onSaveCallback(this.props.id, this.state.reasonId)
    );
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      [name]: data,
    });
  }

  render() {
    return (
      <div className="modal-container">
        <div className="modal-title">
          {RoomBookingsGrid.Resources.declineRoomRequestConfirmationMessage}
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title in-modal">
              {RoomBookingsGrid.Resources.reasons}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <div className="input-group form-select">
              <Select
                options={this.props.reasons}
                onChange={(value: any, option: any) => {
                  this.handleInputChange(
                    nameof((s) => s.reasonId),
                    value
                  );
                }}
                value={this.state.reasonId?.toString()}
                placeholder={General.Resources.select}
              ></Select>
            </div>
            {this.getFieldErrors(nameof((s) => s.paymentRecurrenceUnit)).map(
              (err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              )
            )}
          </div>
        </div>
        <div className="form-line form-bottom-actions">
          <button
            className="btn-big btn-secondary"
            onClick={this.props.onCancelCallback}
          >
            {General.Resources.cancelButtonLabel}
          </button>
          <button className="btn-big btn-primary" onClick={this.onSave}>
            {RoomBookingsGrid.Resources.decline}
          </button>
        </div>
      </div>
    );
  }
}

export default connect((state: ApplicationState, ownProps: IProps) => {
  return {
    validator: DeclineRoomBookingConfirmationValidator,
    ...ownProps,
  };
})(DeclineRoomBookingConfirmationModal as any);
