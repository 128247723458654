import React from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

interface IProps {
  label?: string;
  className?: string;
  errors?: string[];
  value?: any;
  readOnly?: boolean;
  hidden?: boolean;
  onChange?: (data: any) => void;
  name?: string;
}

const DateInput: React.FC<IProps> = ({
  label,
  className,
  errors = [],
  value,
  readOnly,
  hidden,
  onChange,
  name,
}) => {
  const handleDateChange = (date: Date) => {
    const formattedDate = dayjs(date)
      .format("YYYY-MM-DD HH:mm")
      .replace(" ", "T");
    onChange?.(formattedDate);
  };

  const dateValue = value ? new Date(value) : null;

  return (
    <div className="form-item" hidden={hidden}>
      {label && <label>{label}</label>}
      <br />
      <DatePicker
        className={`${className} ${readOnly ? "readonly-date" : ""}`}
        name={name}
        showTimeSelect={true}
        selected={dateValue}
        timeFormat="HH:mm"
        dateFormat="dd-MM-yyyy HH:mm"
        onChangeRaw={(e) => e.preventDefault()}
        disabled={readOnly}
        onChange={handleDateChange}
      />
      {errors.map((err) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default DateInput;
