import React from "react";

import { ISelectListItem } from "../../../models/Common/ISelectListItem";
import Loader from "../Loader";

interface IProps {
  label?: string;
  className?: string;
  errors?: string[];
  options?: ISelectListItem[];
  value?: any;
  readOnly?: boolean;
  hidden?: boolean;
  refreshData?: () => void;
  isDataLoading?: boolean;
  onChange?: (data: any) => void;
  name?: string;
}

const RadioInput: React.FC<IProps> = ({
  label,
  className,
  errors = [],
  options,
  value,
  readOnly,
  hidden,
  refreshData,
  isDataLoading,
  onChange,
  name,
}) => {
  if (!options || options.length === 0) {
    if (refreshData && !isDataLoading) {
      refreshData();
    }
    return <Loader show={true} />;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  return (
    <div className="form-item" hidden={hidden}>
      {label && <label>{label}</label>}
      <div className={className}>
        {options.map((op) => (
          <div
            className="radio"
            style={{ display: "flex", gap: "8px" }}
            key={op.value}
          >
            <input
              type="radio"
              onChange={readOnly ? undefined : handleInputChange}
              value={op.value}
              name={name}
              readOnly={readOnly}
              checked={value?.toString() === op.value}
            />
            {op.text}
          </div>
        ))}
        {errors.map((err) => (
          <span key={err} className="field-validation-error">
            {err}
          </span>
        ))}
      </div>
    </div>
  );
};

export default RadioInput;
